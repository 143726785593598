import { Box, Card, Link, Typography, styled } from "@mui/material";
import { useEffect, useState } from "react";

const FooterWrapper = styled(Card)(
  ({ theme }) => `
        border-radius: 0;
        margin-top: auto;
        padding: ${theme.spacing(2)};
        bottom: 0,
        height: 100px,
        width: '100%',
        py: 2,



`
);

function Footer() {
  const [isScrollable, setIsScrollable] = useState(false);
  

  useEffect(() => {
    const handleResize = () => {
      const body = document.body;
      const html = document.documentElement;
      const documentHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      const windowHeight = window.innerHeight;
      setIsScrollable(documentHeight > windowHeight);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <FooterWrapper className="footer-wrapper">
      <Box
        component="footer"
        sx={{
           position: isScrollable ? "sticky" : "static",
          bottom: 0,}}
      >
        <Box>
          <Typography variant="subtitle1">
            &copy; 2023 - Extreme Hacking
          </Typography>
        </Box>
      </Box>
    </FooterWrapper>
  );
}

export default Footer;
