import ReactMarkdown from "react-markdown";
import { styled } from "@mui/material/styles";
import { Container } from "@mui/material";
import MarkdownRenderer from "../../../../../components/MarkdownRenderer";

const Viewarea = styled(ReactMarkdown)`
  padding: 12;
  font-size-adjust: 0.5;
  font-size: 1.5em;
  font-weight: 300;
  line-height: 140%;
  text-align: justify;
  text-justify: inter-word;

  p {
    line-height: calc(1ex / 0.32);
  }

  h1 {
    font-size: 2.5em;
    line-height: calc(1ex / 0.42);
    margin: calc(1ex / 0.42) 0;
  }

  h2 {
    font-size: 2em;
    line-height: calc(1ex / 0.42);
    margin: calc(1ex / 0.42) 0;
  }

  h3 {
    font-size: 1.75em;
    line-height: calc(1ex / 0.38);
    margin: calc(1ex / 0.38) 0;
  }

  h4 {
    font-size: 1.5em;
    line-height: calc(1ex / 0.37);
    margin: calc(1ex / 0.37) 0;
  }

  p {
    font-size: 1em;
    line-height: calc(1ex / 0.32);
    margin: calc(1ex / 0.32) 0;
  }

  img {
    max-width: 100%;
    height: auto;

  }
`;

const LectureText = (props) => {
  const handleStateChange = (state, prevState) => {
    if (state.ended) {
    }
  };

  return (
    <Container>
      <MarkdownRenderer markdown={props.lecture.content} />
    </Container>
  );
};

export default LectureText;
