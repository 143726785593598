import { Box } from "@mui/material";
import LanguageSwitcher from "./LanguageSwitcher";
import VPN from "./VPN";
import AttackBox from "./AttackBox";
import Subscribe from "../../../../components/Subscribe";

function HeaderButtons() {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Subscribe />
      <AttackBox />
      <VPN />
      <LanguageSwitcher />
    </Box>
  );
}

export default HeaderButtons;
