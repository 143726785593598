import { createSlice } from "@reduxjs/toolkit";
import { doLogin, doGetUserProfile } from "./auth-actions";

//const user = JSON.parse(localStorage.getItem("session"));

const initialState = {
  loading: false,
  isInitialized: false,
  user: null,
  isLoggedIn: false,
  error: null,
  success: false,
};
//? { isLoggedIn: true, user,token:null,error:null }
//: { isLoggedIn: false, user: null,token:null,error:null };

const authSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    initialized(state, action) {
      return {
        ...state,
        isInitialized: true,
      };
    },
    userProfile(state, action) {
      return {
        ...state,
        user: action.payload.user,
      };
    },
    registerSuccess(state, action) {
      return {
        ...state,
      };
    },
    registerFail(state, action) {
      return {
        ...state,
        error: action.payload,
      };
    },
    loginSuccess(state, action) {
      return {
        ...state,
        isLoggedIn: true,
        error: null,
        user: action.payload.user,
      };
    },
    loginFail(state, action) {
      return {
        ...state,
        isLoggedIn: false,
        error: action.payload,
        user: null,
      };
    },
    logout(state, actions) {
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    },
    changePassToken(state, actions) {
      state.token = actions.payload.token;
      state.error = null;
    },
    verifyEmailError(state, actions) {
      return {
        ...state,
        error: actions.payload.error,
      };
    },
  },
  extraReducers(builder) {
    builder

      .addCase(doGetUserProfile.pending, (state, action) => {
        state.statusProfile = "loading";
        state.loading = true;
        state.error = null;
      })
      .addCase(doGetUserProfile.fulfilled, (state, action) => {
        // console.log("doGetUserProfile", action.payload);
        state.user = action.payload;
        state.isLoggedIn = true;
        state.statusProfile = "succeeded";
        state.loading = false;
      })
      .addCase(doGetUserProfile.rejected, (state, action) => {
        // console.log("doGetUserProfile REJECTED", action.error);
        state.statusProfile = "failed";
        state.error = action.error.message;
        state.loading = false;
      });
  },
});

export const authActions = authSlice.actions;

export default authSlice;
