import { Fragment, useCallback, useEffect, useRef, useState } from "react";

import {
  IconButton,
  Box,
  List,
  ListItem,
  Badge,
  ListItemAvatar,
  Button,
  Divider,
  OutlinedInput,
  Typography,
  ListItemText,
  alpha,
  Popover,
  Tooltip,
  Avatar,
  styled,
  useTheme,
} from "@mui/material";
import Text from "../../../../../components/Text";
import Scrollbar from "../../../../../components/Scrollbar";
import LanTwoToneIcon from "@mui/icons-material/LanTwoTone";
import { useTranslation } from "react-i18next";
import useRefMounted from "../../../../../hooks/useRefMounted";
import { getVPNProfile, getVPNServers } from "../../../../../api/lib/auth";
import PublicIcon from "@mui/icons-material/Public";
import LoadSpinner from "../../../../../components/LoadSpinner";
import { LoadingButton } from "@mui/lab";
import ErrorDialog from "../../../../../components/ErrorDialog";

const AvatarGradient = styled(Avatar)(
  ({ theme }) => `
      background: ${theme.colors.gradients.blue1};
      color: ${theme.colors.alpha.trueWhite[100]};
  `
);

const DotLegend = styled("span")(
  ({ theme }) => `
    border-radius: 22px;
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-right: ${theme.spacing(0.5)};
`
);

const OutlinedInputWrapper = styled(OutlinedInput)(
  ({ theme }) => `
    background-color: ${theme.colors.alpha.white[100]};

    .MuiOutlinedInput-notchedOutline {
        border: 0;
    }
`
);

const ListWrapper = styled(List)(
  () => `
    .MuiListItem-root:last-of-type + .MuiDivider-root {
        display: none;
    }
`
);

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
  border-radius: ${theme.general.borderRadiusLg};
`
);

function VPN() {
  const { t } = useTranslation();
  const theme = useTheme();

  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [vpnservers, setVPNServers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [waitingDownload, setWaitingDownload] = useState(false);

  const isMountedRef = useRefMounted();

  function closeErrorDialog() {
    setError(null);
  }

  const getVPNServersApi = useCallback(async () => {
    try {
      const response = await getVPNServers();

      if (isMountedRef.current) {
        setVPNServers(response.data);
        setLoading(false);
      }
    } catch (err) {
      // console.log("ERROR", err);
      setError(err.response.data);
    }
  }, [isMountedRef]);

  const generateProfile = useCallback(
    async (id) => {
      try {
        setWaitingDownload(true);
        const response = await getVPNProfile(id);
        //download file
        const type = response.headers["content-type"];
        const blob = new Blob([response.data], {
          type: type,
          encoding: "UTF-8",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);

        link.download = "extremehacking.ovpn";

        link.click();
        setWaitingDownload(false);
        setOpen(false);
      } catch (err) {
        setWaitingDownload(false);
        setOpen(false);
        setError(err.response.data);
        // console.log("ERROR", err);
      }
    },
    [isMountedRef]
  );

  useEffect(() => {
    if (isOpen === true) {
      getVPNServersApi();
    }
  }, [isOpen, getVPNServersApi]);

  const handleOpen = () => {
    setOpen(true);
    setLoading(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ErrorDialog
        isOpen={!!error}
        onClose={closeErrorDialog}
        errorMessage={error}
      />

      <Tooltip arrow title={t("Generate VPN Profile")}>
        <Badge
          variant="dot"
          overlap="circular"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          sx={{
            ".MuiBadge-badge": {
              background: theme.colors.success.main,
            },
          }}
        >
          <IconButtonWrapper
            color="warning"
            ref={ref}
            onClick={handleOpen}
            sx={{
              background: alpha(theme.colors.primary.main, 0.1),
              transition: `${theme.transitions.create(["background"])}`,
              color: theme.colors.primary.main,

              "&:hover": {
                background: alpha(theme.colors.primary.main, 0.2),
              },
            }}
          >
            <LanTwoToneIcon fontSize="small" />
          </IconButtonWrapper>
        </Badge>
      </Tooltip>

      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {loading === true ? (
          <LoadSpinner />
        ) : (
          <Box minWidth={360} maxWidth={360}>
            <Box
              p={1}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                background: `${alpha(theme.colors.alpha.black[100], 0.07)}`,
              }}
            >
              <Box component="p">{t("VPN Server")}</Box>
            </Box>
            <Divider />

            <Box
              sx={{
                height: 200,
              }}
            >
              <Scrollbar>
                <ListWrapper disablePadding>
                  {vpnservers.map((item) => (
                    <Fragment key={item.id}>
                      <ListItem
                        sx={{
                          py: 1.5,
                          "&:hover": {
                            background: `${theme.colors.alpha.black[5]}`,
                          },
                        }}
                        secondaryAction={
                          <Tooltip
                            arrow
                            title={t("Download here to connect to the VPN")}
                          >
                            <LoadingButton
                              loading={waitingDownload}
                              size="small"
                              variant="text"
                              color="primary"
                              sx={{
                                alignSelf: "center",
                                padding: `${theme.spacing(0.5, 1.5)}`,
                                backgroundColor: `${theme.colors.secondary.lighter}`,
                                textTransform: "uppercase",
                                fontSize: `${theme.typography.pxToRem(11)}`,
                                "&:hover": {
                                  backgroundColor: `${theme.colors.secondary.main}`,
                                  color: `${theme.palette.getContrastText(
                                    theme.colors.secondary.main
                                  )}`,
                                },
                              }}
                              onClick={() => generateProfile(item.id)}
                            >
                              {t("Download OVPN")}
                            </LoadingButton>
                          </Tooltip>
                        }
                      >
                        <ListItemAvatar
                          sx={{
                            minWidth: 38,
                            mr: 1,
                          }}
                        >
                          <Avatar
                            sx={{
                              width: 38,
                              height: 38,
                            }}
                            alt={item.name}
                          >
                            <PublicIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          sx={{
                            flexGrow: 0,
                            maxWidth: "50%",
                            flexBasis: "50%",
                          }}
                          disableTypography
                          primary={
                            <Typography
                              sx={{
                                pb: 0.6,
                              }}
                              color="text.primary"
                              variant="h5"
                            >
                              {item.name}
                            </Typography>
                          }
                          secondary={
                            <Box display="flex" alignItems="flex-start">
                              <DotLegend
                                style={{
                                  background: `${
                                    item.status === true
                                      ? theme.colors.success.main
                                      : theme.colors.error.main
                                  }`,
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: `${theme.typography.pxToRem(11)}`,
                                  lineHeight: 1,
                                }}
                                variant="body1"
                              >
                                <Text
                                  color={
                                    item.status === true ? "success" : "error"
                                  }
                                >
                                  {item.status === true
                                    ? t("Online")
                                    : t("Offline")}
                                </Text>
                              </Typography>
                            </Box>
                          }
                        />
                      </ListItem>
                      <Divider />
                    </Fragment>
                  ))}
                </ListWrapper>
              </Scrollbar>
            </Box>
          </Box>
        )}
      </Popover>
    </>
  );
}

export default VPN;
