import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "#f8f8f8",
    borderLeft: `4px solid ${theme.palette.primary.main}`, 
    borderRight: `4px solid ${theme.palette.primary.main}`,
    marginBottom: 1,
    
    fontWeight: 'bold',
  },
  cardContent: {
    marginTop: 0,
    whiteSpace: 'pre-line',
    
  },
  noteText: {
    
    fontWeight: 'bold',
    marginTop: 0,
  },
}));

const NoteHighlight = ({ text }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      Nota:
      <CardContent className={classes.cardContent}>
        <Box component="div" className={classes.noteText}>
          {text}
        </Box>
      </CardContent>
    </Card>
  );
};

export default NoteHighlight;
