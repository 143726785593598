import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import { useTranslation } from "react-i18next";

function MsgDialog(props) {
  const { isOpen, onClose, errorMessage } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullScreen={fullScreen}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          margin: "auto",
          maxHeight: "70vh",
        },
      }}
    >
      <DialogTitle>
        <InfoTwoToneIcon
          style={{
            color: theme.colors.info.main,
            marginRight: theme.spacing(1),
          }}
        />
        {props.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{props.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
          variant="contained"
          disableElevation
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MsgDialog;
