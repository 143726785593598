import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import router from "./router";
import { SnackbarProvider } from "notistack";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import useAuth from "./hooks/useAuth";
import { CssBaseline } from "@mui/material";
import ThemeProvider from "./theme/ThemeProvider";
import AppInit from "./components/AppInit";
import { useState } from "react";
import { useEffect } from "react";

import TagManager from 'react-gtm-module'
const tagManagerArgs = {
  gtmId: 'GTM-PWBGBF35'
}
TagManager.initialize(tagManagerArgs)



function App() {
  window.dataLayer.push({
    event: 'pageview'
  });

  const location = useLocation();
  const navigate = useNavigate();
  const content = useRoutes(router);
  const auth = useAuth();

  const [lastVisitedPage, setLastVisitedPage] = useState(null);
  useEffect(() => {
    // Recupera a última página visitada do sessionStorage
    const lastPage = sessionStorage.getItem("lastVisitedPage");

    // Redireciona o usuário para a última página visitada
    // caso não seja a /dashboard
    if (lastPage && location.pathname !== lastPage && !"/dashboards/") {
      navigate(lastPage);
    }

   
      
      const params = new URLSearchParams(window.location.search);
      const utmSource = params.get('utm_source');
      const utmMedium = params.get('utm_medium');
      const utmCampaign = params.get('utm_campaign');

      if (utmSource) {
        localStorage.setItem('utm_source', utmSource);
      }

      if (utmMedium) {
        localStorage.setItem('utm_medium', utmMedium);
      }

      if (utmCampaign) {
        localStorage.setItem('utm_campaign', utmCampaign);
      }
      
      window.dataLayer.push({
        'event': 'utmEvent',
        'utm_source': utmSource,
        'utm_medium': utmMedium,
        'utm_campaign': utmCampaign
      });
      
    

  }, []);

  useEffect(() => {
    // Não armazena a página 500 ou a pagina /dashboards/
    // console.log("PATHNAME", location.pathname);

    if (
      location.pathname !== "/status/500" &&
      location.pathname !== "/dashboards/"
    ) {
      // console.log("SAVE PATHNAME", location.pathname);
      sessionStorage.setItem("lastVisitedPage", location.pathname);
    }
  }, [location.pathname]);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider
          maxSnack={6}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <CssBaseline />

          {auth.isInitialized ? content : <AppInit />}
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
