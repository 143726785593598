import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const TypePrdWrapper = styled('span')(
  ({ theme }) => `
      background-color: ${theme.colors.alpha.black[5]};
      padding: ${theme.spacing(0.5, 1)};
      font-size: ${theme.typography.pxToRem(13)};
      border-radius: ${theme.general.borderRadius};
      display: inline-flex;
      align-items: center;
      justify-content: center;
      max-height: ${theme.spacing(3)};
      
      &.MuiLabel {
        &-FR {
          background-color: ${theme.colors.info.lighter};
          color: ${theme.palette.info.main}
        }

        &-PD {
          background-color: ${theme.colors.info.lighter};
          color: #ffeb3b;
        }

        &-private {
          background-color: ${theme.colors.info.lighter};
          color: #ffeb3b;
        }

        &-public {
          background-color: ${theme.colors.info.lighter};
          color: ${theme.palette.info.main}
        }
        
        &-CO {
          background-color: ${theme.colors.secondary.lighter};
          color: ${theme.palette.secondary.main}
        }
        
        &-CL {
          background-color: ${theme.colors.success.lighter};
          color: ${theme.palette.success.main}
        }
        
        &-SP {
          background-color: ${theme.colors.warning.lighter};
          color: ${theme.palette.warning.main}
        }
              
        &-error {
          background-color: ${theme.colors.error.lighter};
          color: ${theme.palette.error.main}
        }
        
        &-info {
          background-color: ${theme.colors.info.lighter};
          color: ${theme.palette.info.main}
        }
        &-subscription {
          background-color: green;
          color: ${theme.palette.white}
        } 
      }
`
);

const TypePrd = ({ className, type = 'seconday', children, ...rest }) => {
  return (
    <TypePrdWrapper className={'MuiLabel-' + type} {...rest}>
      {children}
    </TypePrdWrapper>
  );
};

TypePrd.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.oneOf([
    'FR',
    'PD',
    'CO',
    'CL',
    'SP',
    'private',
    'public',
    'subscription',
    
  ])
};

export default TypePrd;
