import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from '../components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Management

const Users = Loader(lazy(() => import('../content/management/Users')));
const SingleUser = Loader(
  lazy(() => import('../content/management/Users/single'))
);


const managementRoutes = [
  {
    path: '',
    element: <Navigate to="users" replace />
  },
  {
    path: 'users',
    children: [
      {
        path: 'single',
        children: [
          {
            path: ':userId',
            element: <SingleUser />
          }
        ]
      }
    ]
  },
  
 
];

export default managementRoutes;
