import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Box,
  Grid,
  IconButton,
  styled,
  Chip,
  Paper,
  Button,
  alpha,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import MuiAlert from "@mui/material/Alert";
import { WebsocketContext } from "../../contexts/WebSocketContext";

import EngineeringTwoToneIcon from "@mui/icons-material/EngineeringTwoTone";
import ErrorDialog from "../ErrorDialog";
import { Countdown } from "../Clock";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { useTheme } from "@emotion/react";
import LabComponent from "../LabComponent";
import { StaticDatePicker } from "formik-mui-lab";
import Subscribe from "../Subscribe";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ChallengeComponent = ({ challenge, handleAccepted, isCourse }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isConnected, receive, send, isRunning, labId] =
    useContext(WebsocketContext);

  const timeRef = useRef(Date.now());
  const [mobileOpen, setMobileOpen] = useState(false);

  const [error, setError] = useState(null);

  const [snackPosition, setSnackPosition] = useState({
    vertical: "bottom",
    horizontal: "center",
  });

  //const isAccepted = challenge.is_accepted;

  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  const { vertical, horizontal } = snackPosition;
  const [labStatus, setLabStatus] = useState("Verifying");
  const [labUrl, setLabUrl] = useState("");
  const [labCreate, setLabCreate] = useState(false);
  const [labRunning, setLabRunning] = useState(false);
  const [is_accepted, setIsAccepted] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    console.log("Challenge Is Accepted", challenge.is_accepted);
    setIsAccepted(challenge.is_accepted);
    
  }, [challenge]);

  useEffect(() => {
    // console.log(
    //   "isRunning Challenge",
    //   isRunning,
    //   labId,
    //   challenge.id,
    //   challenge.content_type
    // );
    if (isConnected && is_accepted === true) {
      if (
        challenge.content_type === "CT" ||
        challenge.content_type === "VMK" ||
        challenge.content_type === "VM" ||
        challenge.content_type === "VMC"
      ) {
        try {
          if (isRunning === true && labId === challenge.id) {
            if (
              challenge.content_type === "VM" ||
              challenge.content_type === "VMC"
            ) {
              // console.log("GET 80 VMStatus", challenge.id);
              send(JSON.stringify({ cmd: "VMStatus", id: challenge.id }));
            } else {
              // console.log("GET 91 getStatus", challenge.id);
              send(JSON.stringify({ cmd: "getStatus", id: challenge.id }));
            }
          } else if (isRunning === false) {
            // console.log("IS RUNNING FALSE", challenge.id);
            if (
              challenge.content_type === "VM" ||
              challenge.content_type === "VMC"
            ) {
              // console.log("GET 88 VMStatus", challenge.id);
              send(JSON.stringify({ cmd: "VMStatus", id: challenge.id }));
            } else {
              // console.log("GET 102 getStatus", challenge.id);
              send(JSON.stringify({ cmd: "getStatus", id: challenge.id }));
            }
          }
        } catch (error) {
          // console.log(error);
          console.warn(
            "Something went wrong while decoding the Message Payload"
          );
        }
      }
    }
    return () => {
      challenge = null;
    }
  }, [isConnected, is_accepted]);

  useEffect(() => {
    if (receive) {
      const response = JSON.parse(receive);
      console.log("RESPONSE CHALL", response, challenge.id, response.id === challenge.id);
      if (response.id === challenge.id || response.cmd === "Stopped") {
        switch (response.cmd) {
          case "PROGRESS":
            setLabStatus(t('Inicializando...') + response.progress + " %");
            break
          case "Initializing":
            setLabStatus(t('Inicializando...'));
            setLabRunning(false);
            setLabUrl("");
            break
          case "Running":
            setLabUrl(response);
            setLabCreate(false);
            setLabRunning(true);
            setLabStatus(response.cmd);

            break;
          case "Reverting":
            setLabStatus("Reverting...");
            setLabCreate(true);
            setLabUrl("");
            setLabRunning(false);
            break;

          case "Stopped":
            if (labCreate === false) {
              setLabRunning(false);
              setLabCreate(false);
              setLabUrl("");
              setLabStatus("Pronto Para Iniciar");
            }

            break;
          case "Stopping":
            setLabStatus("Parando...");
            setLabRunning(false);

            break;

          case "Terminating":
            setLabStatus("Parando...");
            setLabRunning(false);
            setLabUrl("");

            break;
          case "Accepted":
            setIsAccepted(false);
          case "Error":
            setLabStatus("Pronto Para Iniciar");
            setLabRunning(false);
            setLabCreate(false);
            setLabUrl("");
            setError(response.error);
            console.log("Error", response.error);
            break;
          default:
            break;
        }
      }
      else if(response.id !== challenge.id)
      {
        switch (response.cmd) {
          case "Running":
            setLabStatus("AnotherRunning");
            setLabRunning(false);
            setLabUrl("");
            setLabCreate(false);
            break;  
        
        }
      }
    }
  }, [send, receive]);

  const handleLab = (id) => {
    if (labStatus === "Pronto Para Iniciar" && isRunning === false) {
      setLabCreate(true);
      setLabStatus("Inicializando...");
      send(JSON.stringify({ cmd: "start", id: id }));
      console.log("Start Lab", JSON.stringify({ cmd: "start", id: id }));
    } else if (labRunning === true) {
      setLabUrl("");
      setLabStatus("Parando...");
      send(JSON.stringify({ cmd: "stop", id: id }));
      setLabUrl("");
      setLabRunning(false);
      setLabCreate(false);
    }
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const [currentTab, setCurrentTab] = useState("additional_info");

  const tabs = [
    { value: "additional_info", label: t("Additional Informations") },
    { value: "flags", label: t("Flags") },
    { value: "reviews", label: t("Reviews") },
  ];

  const handleTabsChange = (_event, value) => {
    setCurrentTab(value);
  };

  function closeErrorDialog() {
    setError(null);
  }

  const DownloadClick = (event) => {
    event.preventDefault();

    const filename = challenge.file_download.split("\\").pop().split("/").pop();

    const link = document.createElement("a");
    link.href = challenge.file_download;
    link.download = "filename";
    link.click();
  };

  return (
    <>
      
      <ErrorDialog
        isOpen={!!error}
        onClose={closeErrorDialog}
        errorMessage={error}
      />

      <Grid
        container
        spacing={1}
        sx={{
          marginBottom: 2,
        }}
      >
        {challenge.thumb && (
          <Grid item xs={12} sm={4}>
            <Box
              component={Paper}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Box
                component="img"
                sx={{ maxWidth: 300, objectFit: "contain" }}
                src={challenge.thumb}
              />
            </Box>
          </Grid>
        )}
        <Grid item xs={12} sm={challenge.thumb ? 8 : 12}>
          <Box sx={{ height: "100%" }}>
            <Paper
              sx={{
                p: 2,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography
                  variant="h1"
                  sx={{
                    typography: { lg: "h1", xs: "h4" },
                  }}
                  color="text.primary"
                  component="h2"
                >
                  {challenge.title}
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <Typography
                    sx={{
                      display: {
                        xs: "none",
                        lg: "block",
                      },
                    }}
                    variant="body2"
                    gutterBottom
                  >
                    {challenge.description}
                  </Typography>
                  </Box>
                  <Box sx={{ mt: 2 }}>
                  <Typography
                    sx={{
                      display: {
                        xs: "none",
                        lg: "block",
                      },
                    }}
                    variant="body2"
                    gutterBottom
                  >
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mt: 2,
                }}
              >
               
                {!challenge.is_accepted && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleAccepted();
                    }}
                  >
                    {t("I accept this Challenge")}
                  </Button>
                )}
                 {console.log("is_accepted", is_accepted)}
                {challenge.is_accepted && (
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="baseline"
                    spacing={1}
                  >
                    
                    {(challenge.content_type === "CT" ||
                      challenge.content_type === "VM" ||
                      challenge.content_type === "VMK" ||
                      challenge.content_type === "VMC") && (
                      <>
                        <Grid item>
                          <Tooltip title={t("PowerOn/PowerOff")}>
                            <IconButton
                              aria-label="play/stop"
                              onClick={() => handleLab(challenge.id)}
                              disabled={!isConnected}
                              sx={{ p: 0, margin: 0 }}
                            >
                              {labStatus === "Verifying" || labStatus === "AnotherRunning" ? (
                                <PowerSettingsNewIcon
                                sx={{ height: 36, width: 36 }}
                                color={!isConnected ? "disabled" : "success"}
                              />
                              ):( <PowerSettingsNewIcon
                                sx={{ height: 36, width: 36 }}
                                color={!isConnected ? "disabled" : "error"}
                              />)}
                             
                            </IconButton>
                          </Tooltip>
                        </Grid>

                        <Grid item>
                          <Chip label={t(labStatus)} />
                        </Grid>

                        {labCreate && (
                          <Grid item>
                            <Countdown
                              tgt={challenge.creation_time * 1000}
                            ></Countdown>
                          </Grid>
                        )}
                        {labRunning && (
                          <Grid item>
                            <LabComponent
                              laburl={labUrl}
                              is_visible={true}
                              chall_id={challenge.id}
                              titleShow={false}
                              countDownShow={true}
                            />
                          </Grid>
                        )}
                      </>
                    )}
                    {challenge.content_type === "downloadable" && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          "&:hover": {
                            backgroundColor: alpha(
                              theme.palette.success.main,
                              0.6
                            ),
                            cursor: "pointer",
                          },
                        }}
                        onClick={DownloadClick}
                      >
                        <CloudDownloadIcon fontSize="large" />
                        <Typography variant="h6" sx={{ marginLeft: "10px" }}>
                          {t(
                            "Realize o Download do Arquivo para encontrar a Flag"
                          )}
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                )}
                {!isCourse && (
                  <Box>
                    <Typography
                      variant="h3"
                      sx={{
                        typography: { lg: "h3", xs: "h6" },
                      }}
                      color="text.primary"
                    >
                      {t("Points") + ":" + challenge.points}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

ChallengeComponent.propTypes = {
  challenge: PropTypes.object.isRequired,
};

export default ChallengeComponent;
