import axios from 'axios';
const DEV = process.env.REACT_APP_DEV

let baseURL = "https://"+window.location.hostname + "/"
if (DEV === "true")
{
  baseURL = "http://"+window.location.hostname + ":8080/"
}
// console.log("API Final",DEV,baseURL)

const axiosClient = axios.create({
   
    baseURL: `${baseURL}`,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  });

  axiosClient.interceptors.request.use(
    async (config) => {
      const session = JSON.parse(localStorage.getItem("session"));
      if (session) {
        config.headers = {
          ...config.headers,
          Authorization: `Token ${session}`,
        };
      }
  
      return config;
    }
  );

  axiosClient.interceptors.response.use((response) => {
      //console.log('RESPONSE', response)
      if (response?.status === 401) {
        // console.log('Response 401', response)
        localStorage.removeItem("session");
         window.location.href = '/account/login'; 
      }
     else if (response.status === 200) {
        return response
      }
    }, (error) => {

      if (error?.response?.status === 500) {
        // console.log('Error 500', error)
        window.location.href = '/status/500'; 
        
      } 
      
      if (error?.response?.status === 401) {
        // console.log('Remove Session and redirect to /account/login', error)
        localStorage.removeItem("session");
        window.location.href = "/account/login"
        
      }
      
      return Promise.reject(error)
    })
  

export default axiosClient;