import axiosClient from "../apiClient";


export async function  getCourses(term){
   
    return axiosClient.get('/api/courses/');
   
}

export async function  getCoursesCategory(term){
   
    return axiosClient.get('/api/category/');
   
}

export async function  getCourseByCat(category_id){
   
    return axiosClient.get(`/api/courses/by-cat/${category_id}/`);
   
}

export async function  getCourseSearch(term){
   
    return axiosClient.get(`/api/courses/?search=${term}`);
   
}

export async function  getCourseDetail(uuid){
   
    return axiosClient.get(`/api/courses/${uuid}`);
   
}

export async function  isEnrolled(uuid){
   
    return axiosClient.get(`/api/courses/${uuid}/isEnrolled/`);
   
}

export async function  Enroll(uuid){
   
    return axiosClient.post(`/api/courses/${uuid}/Enroll/`);
   
}

export async function  getStudentProgress(uuid){
   
    return axiosClient.get(`/api/progress/${uuid}/last_content/`);
   
}

export async function  courseReview(uuid,data){
   
    return axiosClient.post(`/api/courses/${uuid}/review/`,data);
   
}

export async function  getContent(uuid,moduleId,contentId){
   
    return axiosClient.get(`/api/courses/${uuid}/content/${moduleId}/${contentId}/`);
   
}

export async function getGetNext(uuid,moduleId,contentId){
   
    return axiosClient.post(`/api/courses/${uuid}/next/${moduleId}/${contentId}/`);
   
}


export async function setCompletedContent(uuid,moduleId,contentId){
   
    return axiosClient.post(`/api/courses/${uuid}/complete/${moduleId}/${contentId}/`);
   
}

export async function  getPreviousContent(uuid,moduleId,contentId){
   
    return axiosClient.post(`/api/courses/${uuid}/previous/${moduleId}/${contentId}/`);
   
}

export async function  getQuiz(moduleId){
   
    return axiosClient.get(`/api/quiz/${moduleId}/`);
   
}

export async function  getQuestionHint(moduleId,questionId){
   
    return axiosClient.get(`/api/quiz/${moduleId}/hint/${questionId}`);
   
}

export async function  getCategories(){
   
    return axiosClient.get('/api/category/');
   
}

export async function  sendSimpleAsnswer(quizId,questionId,data){
    // console.log("DATA ",data)
    return axiosClient.post(`/api/quiz/${quizId}/validate/${questionId}/`,data);
   
}

