import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  IconButton,
  Box,
  List,
  ListItem,
  Badge,
  ListItemAvatar,
  Button,
  Divider,
  OutlinedInput,
  Typography,
  ListItemText,
  alpha,
  Popover,
  Tooltip,
  Avatar,
  styled,
  useTheme,
} from "@mui/material";
import Text from "../../../../../components/Text";
import Scrollbar from "../../../../../components/Scrollbar";
import LanTwoToneIcon from "@mui/icons-material/LanTwoTone";
import { useTranslation } from "react-i18next";
import useRefMounted from "../../../../../hooks/useRefMounted";
import { getVPNProfile, getVPNServers } from "../../../../../api/lib/auth";
import PublicIcon from "@mui/icons-material/Public";
import LoadSpinner from "../../../../../components/LoadSpinner";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../../../../hooks/useAuth";
import { WebsocketContext } from "../../../../../contexts/WebSocketContext";
import LaptopTwoToneIcon from "@mui/icons-material/LaptopTwoTone";
import MsgDialog from "../../../../../components/MsgDialog";
import ErrorDialog from "../../../../../components/ErrorDialog";
import { Countdown } from "../../../../../components/Clock";

const AvatarGradient = styled(Avatar)(
  ({ theme }) => `
      background: ${theme.colors.gradients.blue1};
      color: ${theme.colors.alpha.trueWhite[100]};
  `
);

const DotLegend = styled("span")(
  ({ theme }) => `
    border-radius: 22px;
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-right: ${theme.spacing(0.5)};
`
);

const OutlinedInputWrapper = styled(OutlinedInput)(
  ({ theme }) => `
    background-color: ${theme.colors.alpha.white[100]};

    .MuiOutlinedInput-notchedOutline {
        border: 0;
    }
`
);

const ListWrapper = styled(List)(
  () => `
    .MuiListItem-root:last-of-type + .MuiDivider-root {
        display: none;
    }
`
);

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
  border-radius: ${theme.general.borderRadiusLg};
`
);

function AttackBox() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { user, logout } = useAuth();

  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const [snackPosition, setSnackPosition] = useState({
    vertical: "top",
    horizontal: "center",
  });

  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  const { vertical, horizontal } = snackPosition;

  //const [vpnservers, setVPNServers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [waitingRunning, setWaitingRunning] = useState(false);
  const [isRunning, setisRunning] = useState(false);
  const [instanceID, setInstanceID] = useState(null);
  const [timesup, setTimesup] = useState(false);
  const [labelCMD, setLabelCMD] = useState("Start");
  const [error, setError] = useState(null);

  const [attackboxURL, setAttackboxURL] = useState(null);

  const [isConnected, receive, send] = useContext(WebsocketContext);

  const isMountedRef = useRefMounted();
  const intervalCheck = useRef(null);

  useEffect(() => {
    
    if (isConnected) {
      try {
        if (isRunning === true) {
          
          intervalCheck.current = setInterval(() => {
            send(JSON.stringify({ cmd: "AttackBoxStatus", id: instanceID }));
          }, 30000);
        } else {
          if (intervalCheck.current) {
            
            clearInterval(intervalCheck.current);
          }
        }
      } catch (error) {
       
        console.warn("Something went wrong while decoding the Message Payload");
      }
    }

    return () => {
      if (intervalCheck.current) {
        
        clearInterval(intervalCheck.current);
      }
    };
  }, [isConnected, isRunning, instanceID]);

  useEffect(() => {
    if (receive) {
      const response = JSON.parse(receive);
      //console.log("RESPONSE", response);
      switch (response.cmd) {
        case "AttackBoxRunning":
          setWaitingRunning(false);
          setAttackboxURL(response.url);
          setInstanceID(response.instanceID);
          setTimesup(response.times_up);
          setLabelCMD("Stop");
          setWaitingRunning(false);
          setisRunning(true);

          break;
        case "AttackBoxStopped":
          setisRunning(false);
          setAttackboxURL(null);
          setInstanceID(null);
          setWaitingRunning(false);
          setTimesup(null);
          setLabelCMD("Start");
          break;

        case "AttackBoxError":
          setisRunning(false);
          setAttackboxURL(null);
          setError(response.error);
          setInstanceID(null);
          setLabelCMD("Start");
          setWaitingRunning(false);

          break;
        default:
          //setLabUrl("")
          break;
      }
    }
  }, [send, receive]);

  const handleOpen = () => {
    setOpen(true);
    setLoading(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAttackBox = () => {
    if (labelCMD === "Start") {
      setWaitingRunning(true);
      send(JSON.stringify({ cmd: "AttackBoxStart" }));
      setLabelCMD("Starting...");
    }
    if (labelCMD === "Stop") {
      setWaitingRunning(true);
      send(JSON.stringify({ cmd: "AttackBoxStop", id: instanceID }));
      setLabelCMD("Stopping...");
    }
  };

  function closeErrorDialog() {
    setError(null);
  }

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <>
      <ErrorDialog
        isOpen={!!error}
        onClose={closeErrorDialog}
        errorMessage={error}
      />

      <Tooltip arrow title={t("ExtremeBox")}>
        <Badge
          variant="dot"
          overlap="circular"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          sx={{
            ".MuiBadge-badge": {
              //add a dot indicator by if isConnected is true prymaray.main else secondary.main
              backgroundColor: isConnected
                ? theme.colors.success.main
                : theme.colors.error.main,
            },
          }}
        >
          <IconButtonWrapper
            color="warning"
            ref={ref}
            onClick={handleOpen}
            sx={{
              background: alpha(theme.colors.primary.main, 0.1),
              transition: `${theme.transitions.create(["background"])}`,
              color: theme.colors.primary.main,

              "&:hover": {
                background: alpha(theme.colors.primary.main, 0.2),
              },
            }}
          >
            <LaptopTwoToneIcon fontSize="small" />
          </IconButtonWrapper>
        </Badge>
      </Tooltip>

      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box minWidth={360} maxWidth={360}>
          <Box
            p={1}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              background: `${alpha(theme.colors.alpha.black[100], 0.07)}`,
            }}
          >
            <Box component="p">{t("ExtremeBox")}</Box>
          </Box>
          <Divider />

          <Box
            sx={{
              height: 200,
            }}
          >
            <Scrollbar>
              <ListWrapper disablePadding>
                <Fragment key={1}>
                  <ListItem
                    sx={{
                      py: 1.5,
                      "&:hover": {
                        background: `${theme.colors.alpha.black[5]}`,
                      },
                    }}
                    // onClick={() => {
                    //   isRunning ? openInNewTab(attackboxURL) : null;
                    // }}
                    secondaryAction={
                      <Tooltip arrow title={t(labelCMD)}>
                        <LoadingButton
                          loading={waitingRunning}
                          disabled={!isConnected}
                          size="small"
                          variant="text"
                          color="primary"
                          sx={{
                            alignSelf: "center",
                            padding: `${theme.spacing(0.5, 1.5)}`,
                            backgroundColor: `${theme.colors.secondary.lighter}`,
                            textTransform: "uppercase",
                            fontSize: `${theme.typography.pxToRem(11)}`,
                            "&:hover": {
                              backgroundColor: `${theme.colors.secondary.main}`,
                              color: `${theme.palette.getContrastText(
                                theme.colors.secondary.main
                              )}`,
                            },
                          }}
                          onClick={() => handleAttackBox()}
                        >
                          {t(labelCMD)}
                        </LoadingButton>
                      </Tooltip>
                    }
                  >
                    <Box
                      sx={{
                        display: "flex",
                        cursor: isRunning ? "pointer" : "default",
                      }}
                      onClick={() => {
                        isRunning && openInNewTab(attackboxURL);
                      }}
                    >
                      <ListItemAvatar
                        sx={{
                          minWidth: 38,
                          mr: 1,
                        }}
                      >
                        <Avatar
                          sx={{
                            width: 38,
                            height: 38,

                            backgroundColor: isRunning
                              ? "success.main"
                              : "secondary.main",
                          }}
                          onClick={() => isRunning && openInNewTab(attackboxURL)}
                          alt={t("ExtremeBox")}
                        >
                          <LaptopTwoToneIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        sx={{
                          flexGrow: 0,
                          maxWidth: "50%",
                          flexBasis: "50%",
                        }}
                        disableTypography
                        primary={
                          <Typography
                            sx={{
                              pb: 0.6,
                              cursor: isRunning ? "pointer" : "default",
                            }}
                            color="text.primary"
                            variant="h5"
                          >
                            {t("ExtremeBox")}
                          </Typography>
                        }
                        secondary={
                          <Box display="flex" alignItems="flex-start">
                            {isRunning && !waitingRunning && (
                              <Countdown
                                tgt={timesup * 1000}
                                showMoreTime={false}
                                chall_id={null}
                                showTitle={false}
                              ></Countdown>
                            )}
                            {!isRunning && waitingRunning && (
                              <Countdown
                                tgt={180 * 1000}
                                showMoreTime={false}
                                chall_id={null}
                                showTitle={false}
                              ></Countdown>
                            )}
                            {isRunning && waitingRunning && (
                              <>
                                <DotLegend
                                  style={{
                                    background: `${
                                      isRunning === true
                                        ? theme.colors.success.main
                                        : theme.colors.error.main
                                    }`,
                                  }}
                                />
                                <Typography
                                  sx={{
                                    fontSize: `${theme.typography.pxToRem(11)}`,
                                    lineHeight: 1,
                                  }}
                                  variant="body1"
                                >
                                  <Text
                                    color={
                                      isRunning === true ? "success" : "error"
                                    }
                                  >
                                    {isRunning === true
                                      ? t("Running")
                                      : t("Stopped")}
                                  </Text>
                                </Typography>
                              </>
                            )}
                            {!isRunning && !waitingRunning && (
                              <>
                                <DotLegend
                                  style={{
                                    background: `${
                                      isRunning === true
                                        ? theme.colors.success.main
                                        : theme.colors.error.main
                                    }`,
                                  }}
                                />
                                <Typography
                                  sx={{
                                    fontSize: `${theme.typography.pxToRem(11)}`,
                                    lineHeight: 1,
                                  }}
                                  variant="body1"
                                >
                                  <Text
                                    color={
                                      isRunning === true ? "success" : "error"
                                    }
                                  >
                                    {isRunning === true
                                      ? t("Running")
                                      : t("Stopped")}
                                  </Text>
                                </Typography>
                              </>
                            )}
                          </Box>
                        }
                      />
                    </Box>
                  </ListItem>
                  <Divider />
                </Fragment>
              </ListWrapper>
            </Scrollbar>
          </Box>
        </Box>
      </Popover>
    </>
  );
}

export default AttackBox;
