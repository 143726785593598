import React, { useCallback, useEffect, useState } from "react";

import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Snackbar,
} from "@mui/material";

import SendIcon from "@mui/icons-material/Send";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import {
  getQuestionHint,
  sendSimpleAsnswer,
} from "../../../../../../api/lib/course";
import useRefMounted from "../../../../../../hooks/useRefMounted";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import MuiAlert from "@mui/material/Alert";
import MarkdownRenderer from "../../../../../../components/MarkdownRenderer";
import { useTheme } from "@mui/styles";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const True_or_False = (props) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const [openHint, setOpenHint] = useState(false);
  const [answer, setAnswer] = useState(props.answer);
  const [hint, setHint] = useState("");
  const [retrieveHint, setRetrieveHint] = useState(false);
  const isMountedRef = useRefMounted();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(props.is_solved);
  const [erro, setErro] = useState(null);
  const { t } = useTranslation();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const [snackPosition, setSnackPosition] = useState({
    vertical: "bottom",
    horizontal: "center",
  });

  const { vertical, horizontal } = snackPosition;

  const handleCloseSnack = (event) => {
    setOpenError(false);
    setOpenSuccess(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenHint = () => {
    setOpenHint(true);
  };

  const handleCloseHint = () => {
    setOpenHint(false);
  };

  const getHint = useCallback(async () => {
    try {
      const response = await getQuestionHint(props.quiz_id, props.uuid);

      if (isMountedRef.current) {
        setHint(response.data.hint);
      }
    } catch (err) {
      // console.log("ERROR GET HINT", err);
    }
  }, [hint, isMountedRef]);

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    setErro(null);

    try {
      let data = { ans: answer };

      let response = await sendSimpleAsnswer(props.quiz_id, props.id, data);

      if (response.data.status == "correct") {
        setOpenSuccess(true);
        setDisabled(true);
      } else {
        setOpenError(true);
        setAnswer("");
      }
    } catch (erro) {
      setOpenError(true);
    } finally {
      setLoading(false);
    }
  }

  function handleChange(event) {
    setAnswer(event.target.value);
  }

  const handleGetHint = () => {
    handleClose();
    setRetrieveHint(true);
  };

  useEffect(() => {
    if (retrieveHint) {
      getHint();
      setOpenHint(true);
      setRetrieveHint(false);
    }
  }, [retrieveHint, getHint]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="baseline"
      >
        <Grid item>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              onChange={handleChange}
              defaultValue={answer}
              value={answer}
            >
              {props.choices.map((el, idx) => {
                return (
                  <FormControlLabel
                    key={idx}
                    value={el.id}
                    control={<Radio />}
                    label={t(el.choice)}
                    disabled={disabled}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          space={2}
        >
          <Grid item xs={props.has_hint ? 8 : 12}>
            <LoadingButton
              loading={loading}
              fullWidth
              type="submit"
              variant="contained"
              endIcon={<SendIcon />}
              disabled={disabled}
            >
              {t("Send")}
            </LoadingButton>
          </Grid>
          {props.has_hint && (
            <Grid item xs={2}>
              <Button
                fullWidth
                variant="outlined"
                endIcon={<TipsAndUpdatesIcon />}
                onClick={handleClickOpen}
              >
                {t("Hint")}
              </Button>
            </Grid>
          )}
          <Snackbar
            open={openSuccess}
            autoHideDuration={2000}
            onClose={handleCloseSnack}
            anchorOrigin={{ vertical, horizontal }}
            key={"success"}
            sx={{ zIndex: 10000 }}
          >
            <Alert
              severity="success"
              sx={{ width: "100%" }}
              onClose={handleCloseSnack}
            >
              {t("Congratulations! Correct answer")} ✅
            </Alert>
          </Snackbar>
          <Snackbar
            open={openError}
            autoHideDuration={2000}
            onClose={handleCloseSnack}
            key={"wrong"}
            anchorOrigin={{ vertical, horizontal }}
            sx={{ zIndex: 10000 }}
          >
            <Alert
              severity="error"
              sx={{ width: "100%" }}
              onClose={handleCloseSnack}
            >
              {t("Opss! this is not the correct answer")} ❌!
            </Alert>
          </Snackbar>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("The tips come at a cost.")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("This tip will cost") + " " + props.hint_cost + t("pontos")}.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>{t("I don't want the tip")}</Button>
              <Button onClick={handleGetHint} autoFocus>
                {t("I Accept")}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openHint}
            onClose={handleCloseHint}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <TipsAndUpdatesIcon />
              {t("Enjoy your tip")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {hint}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseHint}>{t("Thanks")}</Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </form>
  );
};

export default True_or_False;
