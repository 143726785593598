import React from "react";
// import "./ControlIcons.css";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import { FastRewind } from "@mui/icons-material";
import { FastForwardSharp } from "@mui/icons-material";
import { PlayArrowSharp } from "@mui/icons-material";
import { PauseSharp } from "@mui/icons-material";
import { VolumeUp } from "@mui/icons-material";
import { VolumeOff } from "@mui/icons-material";
import { Fullscreen } from "@mui/icons-material";
import Popover from "@mui/material/Popover";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material";

const getCustomControlsStyle = (theme,showControls) => {
  return {
    controlsDiv: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: showControls ? "flex" : "none", // definindo o valor da propriedade display com base no valor do parâmetro showControls
      flexDirection: "column",
      justifyContent: "space-between",
      zIndex: 2,
      background: "rgba(0,0,0,0.6)",
      maxHeight: "90%",
      width: "100%",
      
  
    },
    controlsNav: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between"
    },
    controlsIcons: {
      color: "white",
      margin: "0px 8px",
      [theme.breakpoints.down("sm")]: {
        minWidth: "16px",
      },
    },
    controlsIconsHover: {
      color: "#fff",
      transform: "scale(1)"
    },
    bottomIcons: {
      color: "#999"
    },
    bottomIconsHover: {
      color: "white"
    },
    volumeSlider: {
      width: '50%',
      marginTop: '-50px',
      marginLeft: '130px',

    }
  };
};




const ControlIcons = ({
  title,
  showControls,
  playandpause,
  playing,
  rewind,
  fastForward,
  muting,
  muted,
  volumeChange,
  volumeSeek,
  volume,
  playRate,
  playerbackRate,
  fullScreenMode,
  onSeek,
  played,
  onSeekMouseUp,
  onSeekMouseDown,
  fullMovieTime,
  playedTime,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();

  const handlePopOver = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "playbackrate-popover" : undefined;

  function ValueLabelComponent(props) {
    const { children, value } = props;
    const roundedValue = Math.round(value * 100) / 100;

    return (
      <Tooltip enterTouchDelay={0} placement="top" title={roundedValue}>
        {children}
      </Tooltip>
    );
  }

  const PrettoSlider = styled(Slider)({
    height: 5,
    "& .MuiSlider-track": {
      border: "none",
    },
    "& .MuiSlider-thumb": {
      height: 16,
      width: 16,
      backgroundColor: "#fff",
      border: "2px solid currentColor",
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&:before": {
        display: "none",
      },
    },
    "& .MuiSlider-valueLabel": {
      lineHeight: 1.2,
      fontSize: 12,
      background: "unset",
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: "50% 50% 50% 0",
      backgroundColor: "#52af77",
      transformOrigin: "bottom left",
      transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
      "&:before": { display: "none" },
      "&.MuiSlider-valueLabelOpen": {
        transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
      },
      "& > *": {
        transform: "rotate(45deg)",
      },
    },
  });

  const customControlsStyle = getCustomControlsStyle(theme,showControls);

  return (
    <div
      style={customControlsStyle.controlsDiv}
     
    >
      {/* Top Controls */}
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="start"
        style={{ padding: 16 }}
      >
        <Grid item>
          <Typography variant="h5" style={{ color: "white" }}>
            Extreme Hacking
          </Typography>
        </Grid>
      </Grid>

      {/* Middle Controls */}
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <IconButton
          
          className={customControlsStyle.controlsIcons}
          aria-label="reqind"
          onClick={rewind}
        >
          <FastRewind fontSize="large" style={{ color: "white" }} />
        </IconButton>

        <IconButton
          className={customControlsStyle.controlsIcons}
          aria-label="reqind"
          onClick={playandpause}
        >
          {playing ? (
            <PauseSharp fontSize="large" style={{ color: "white" }} />
          ) : (
            <PlayArrowSharp fontSize="large" style={{ color: "white" }} />
          )}
        </IconButton>

        <IconButton
          className={customControlsStyle.controlsIcons}
          aria-label="reqind"
          onClick={fastForward}
        >
          <FastForwardSharp fontSize="large" style={{ color: "white" }} />
        </IconButton>
      </Grid>

      {/* Bottom Controls */}
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        style={{ padding: 16 }}
      >
        <Grid item>
          <Typography variant="h5" style={{ color: "white" }}>
            {title}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <PrettoSlider
            min={0}
            max={100}
            value={played * 100}
            onChange={onSeek}
            onMouseDown={onSeekMouseDown}
            onChangeCommitted={onSeekMouseUp}
            valueLabelDisplay="auto"
            // aria-label="custom thumb label"
            components={{
              ValueLabel: ValueLabelComponent,
            }}
          />
          <Grid container direction="row" justifyContent="space-between">
            <Typography variant="h7" style={{ color: "white" }}>
              {playedTime}
            </Typography>
            <Typography variant="h7" style={{ color: "white" }}>
              {fullMovieTime}
            </Typography>
          </Grid>
        </Grid>

        <Grid item  style={{ flexGrow: 1 }}>
          <Grid container alignItems="center" direction="row">
            <IconButton
              
              aria-label="reqind"
              onClick={playandpause}
            >
              {playing ? (
                <PauseSharp fontSize="large" style={{ color: "white" }} />
              ) : (
                <PlayArrowSharp fontSize="large" style={{ color: "white" }} />
              )}
            </IconButton>

            <IconButton
             
              aria-label="reqind"
              onClick={muting}
            >
              {muted ? (
                <VolumeOff fontSize="large" style={{ color: "white" }} />
              ) : (
                <VolumeUp fontSize="large" style={{ color: "white" }} />
              )}
            </IconButton>

            <Typography style={{ color: "#fff", paddingTop: "5px" }}>
              {Math.round(volume * 100)}
            </Typography>
            <Grid item xs={4}>
            <Slider
              
              aria-label="Volume"
              min={0}
              max={100}
              value={volume * 100}
              onChange={volumeChange}
              onChangeCommitted={volumeSeek}
              
              sx={{ mx: 3,width:100,mt:1 }}
              components={{
                ValueLabel: ValueLabelComponent,
              }}
            />
          </Grid>
    </Grid>
  </Grid>

        <Grid item>
          <Button
            variant="text"
            onClick={handlePopOver}
            className={customControlsStyle.controlsIcons}
          >
            <Typography>{playerbackRate}X</Typography>
          </Button>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <Grid container direction="column-reverse">
              {[0.5, 1, 1.5, 2].map((rate) => (
                <Button variant="text" onClick={() => playRate(rate)}>
                  <Typography
                    color={rate === playerbackRate ? "secondary" : "default"}
                  >
                    {rate}
                  </Typography>
                </Button>
              ))}
            </Grid>
          </Popover>

        
        </Grid>
      </Grid>
    </div>
  );
};

export default ControlIcons;
