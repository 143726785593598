import React from "react";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import TerminalCode from "../TerminalCode";
import remarkGfm from "remark-gfm";
import remarkGemoji from "remark-gemoji";
import { styled } from "@mui/material/styles";
import { Card, Typography } from "@mui/material";
import { Note } from "@mui/icons-material";
import NoteHighlight from "../NoteHighligth";

const Viewarea = styled(ReactMarkdown)`
  // padding: 12;
  font-size-adjust: 0.5;
  font-size: 1.5em;
  font-weight: 300;
  line-height: 140%;
  text-align: justify;
  text-justify: inter-word;

  h1 {
    font-size: 2.5em;
    line-height: calc(1ex / 0.42);
    margin: calc(1ex / 0.42) 0;
  }

  h2 {
    font-size: 2em;
    line-height: calc(1ex / 0.42);
    margin: calc(1ex / 0.42) 0;
  }

  h3 {
    font-size: 1.75em;
    line-height: calc(1ex / 0.38);
    margin: calc(1ex / 0.38) 0;
  }

  h4 {
    font-size: 1.5em;
    line-height: calc(1ex / 0.37);
    margin: calc(1ex / 0.37) 0;
  }

  p {
    font-size: 1em;
    line-height: calc(1ex / 0.32);
    margin: calc(1ex / 0.32) 0;
  }

  img {
    max-width: 80%;
    height: auto;
    display: block;
    margin: auto;

  }

  .inlineCode{
    display: inline;
    padding: 0 4px;
    background-color: #4b4b59;
    color: white;
}

highlighted: {
  backgroundColor: '#FFFF00',
  display: 'inline',
},

table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 18px;
  text-align: left;
  color: ${({ theme }) => theme.textColor || '#333'};
}

th, td {
  padding: 12px 15px;
  border: 1px solid ${({ theme }) => theme.borderColor || '#ddd'};
}

th {
  background-color: ${({ theme }) => theme.headerBgColor || '#f2f2f2'};
}

tr:nth-child(even) {
  background-color: ${({ theme }) => theme.evenRowBgColor || '#f9f9f9'};
}

tr:nth-child(odd) {
  background-color: ${({ theme }) => theme.oddRowBgColor || '#ffffff'};
}


`;


const MarkdownRenderer = ({ markdown }) => {
  const renderers = {
    code({ node, inline, className, children, ...props }) {
      // validate if is language-bash
      const match = /^language-(\w+)/.exec(className || "");
      const language = match ? match[1] : 'bash';
      
      if (className === "language-bash" && !inline) {
        return <TerminalCode code={String(children).replace(/\n$/, "")} />;
      }
      else if (className === "language-note" && !inline) {
        return (
         <NoteHighlight text={String(children).replace(/\n$/, "")} />
        );

      }else if ((JSON.stringify(match) === JSON.stringify(/^language-(\w+)/.exec(className || ""))) && !inline) 
      {
        return (
         
          <SyntaxHighlighter
              children={String(children).replace(/\n$/, "")}
              language={language}
              showLineNumbers={true}
              {...props}
          />
         
          
        );
        } else if (inline) {
          //{console.log("inline", inline, "children", children, "className", className, "props", props)}
          return ( <code className="inlineCode" {...props}>
            {children}
          </code>)
        
      } else {
        return (
          <code className={className} {...props}>
            {children}
          </code>
        );
      }
    },
  };

  return (
    <Viewarea
      remarkPlugins={[remarkGfm, remarkGemoji]}
      components={renderers}
      children={markdown}
    />
  );
};

export default MarkdownRenderer;
