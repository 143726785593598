import { Suspense, lazy } from 'react';

import SuspenseLoader from '../components/SuspenseLoader';
import Guest from '../components/Guest';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Account

const LoginCover = Loader(
  lazy(() => import('../content/pages/Auth/Login/Cover'))
);


const RegisterCover = Loader(
  lazy(() => import('../content/pages/Auth/Register/Cover'))
);

const SupportCover = Loader(
  lazy(() => import('../content/pages/Auth/Support/Cover'))
);

const RecoverPassword = Loader(
  lazy(() => import('../content/pages/Auth/RecoverPassword'))
);

const EmailSent = Loader(
  lazy(() => import('../content/pages/Auth/EmailSent'))
);

const EmailValidation = Loader(
  lazy(() => import('../content/pages/Auth/VerifyEmail'))
);

const PasswordChangeTokenForm = Loader(
  lazy(() => import('../content/pages/Auth/PasswordForgot'))
);

const accountRoutes = [
  {
    path: 'login',
    element: (
      <Guest>
        <LoginCover />
      </Guest>
    )
  },
  {
    path: 'register',
    element: (
      <Guest>
        <RegisterCover />
      </Guest>
    )
  },
  {
    path: 'login',
    element: <LoginCover />
  },
  {
    path: 'recover-password',
    element: <RecoverPassword />
  },
  {
    path: 'register',
    element: (
      <Guest>
        <RegisterCover />
      </Guest>
    )
  },
  {
    path: 'register',
    element: <RegisterCover />
  },
  {
    path: 'email-sent',
    element: <EmailSent />
  },
  {
    
    path: 'email-verify/:token',
    element: <EmailValidation />
  },
  {
    path: 'password-forgot/:token',
    element: <PasswordChangeTokenForm />
  },
  {
    path: 'support',
    element: <SupportCover />
  },
  
];

export default accountRoutes;
