import IconDiscord from "../../../../components/IconDiscord";
import SupportAgentTwoToneIcon from '@mui/icons-material/SupportAgentTwoTone';

const menuItems = [
  {
    heading: "Help",
    items: [
     
      {
        name: "Discord",
        icon: IconDiscord,
        link: "https://discord.gg/BHMC5NsPMp",
        external: true,
      },
      {
        name: "Suporte",
        icon: SupportAgentTwoToneIcon,
        link: "/account/support",
      },
    ],
  },
];

export default menuItems;
