import { useContext, useRef, useState } from "react";
import useAuth from "../../../../hooks/useAuth";
import { NavLink, useNavigate } from "react-router-dom";

import {
  Avatar,
  Box,
  Button,
  Divider,
  MenuList,
  alpha,
  IconButton,
  MenuItem,
  ListItemText,
  Popover,
  Typography,
  styled,
  useTheme,
  Tooltip,
  Chip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import ChevronRightTwoToneIcon from "@mui/icons-material/ChevronRightTwoTone";
import CheckTwoToneIcon from "@mui/icons-material/CheckTwoTone";
import { ThemeContext } from "../../../../theme/ThemeProvider";
import Subscribe from "../../../../components/Subscribe";

const DotLegend = styled("span")(
  ({ theme }) => `
    border-radius: 22px;
    width: ${theme.spacing(1.38)};
    height: ${theme.spacing(1.4)};
    display: inline-block;
    border: ${theme.colors.alpha.white[100]} solid 2px;
`
);

const UserBoxButton = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(4)};
  padding: 0;
  height: ${theme.spacing(4)};
  margin-left: ${theme.spacing(1)};
  border-radius: ${theme.general.borderRadiusLg};
  
  &:hover {
    background: ${theme.colors.primary.main};
  }
`
);

const UserAvatar = styled(Avatar)(
  ({ theme }) => `
        height: 90%;
        width: 90%;
        border-radius: ${theme.general.borderRadiusLg};
`
);

const MenuListWrapperPrimary = styled(MenuList)(
  ({ theme }) => `
  padding: ${theme.spacing(2)};

  & .MuiMenuItem-root {
      border-radius: 50px;
      padding: ${theme.spacing(1, 1, 1, 2.5)};
      min-width: 200px;
      margin-bottom: 2px;
      position: relative;
      color: ${theme.colors.alpha.black[100]};

      &.Mui-selected,
      &:hover,
      &.MuiButtonBase-root:active {
          background: ${theme.colors.primary.lighter};
          color: ${theme.colors.primary.main};
      }

      &:last-child {
          margin-bottom: 0;
      }
    }
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${alpha(theme.colors.alpha.black[100], 0.08)};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${theme.palette.secondary.light}
`
);

const ButtonWrapper = styled(Box)(
  ({ theme }) => `
        cursor: pointer;
        position: relative;
        border-radius: ${theme.general.borderRadiusXl};
        padding: ${theme.spacing(0.8)};
        display: flex;
        flex-direction: row;
        align-items: stretch;
        min-width: 80px;
        box-shadow: 0 0 0 2px ${theme.colors.primary.lighter};

        &:hover {
            box-shadow: 0 0 0 2px ${theme.colors.primary.light};
        }

        &.active {
            box-shadow: 0 0 0 2px ${theme.palette.primary.main};

            .colorSchemeWrapper {
                opacity: .6;
            }
        }
  `
);

const ColorSchemeWrapper = styled(Box)(
  ({ theme }) => `

    position: relative;

    border-radius: ${theme.general.borderRadiusXl};
    height: 28px;
    
    &.colorSchemeWrapper {
        display: flex;
        align-items: stretch;
        width: 100%;

        .primary {
            border-top-left-radius: ${theme.general.borderRadiusXl};
            border-bottom-left-radius: ${theme.general.borderRadiusXl};
        }

        .secondary {
            border-top-right-radius: ${theme.general.borderRadiusXl};
            border-bottom-right-radius: ${theme.general.borderRadiusXl};
        }

        .primary,
        .secondary,
        .alternate {
            flex: 1;
        }
    }

    &.pureLight {
        .primary {
            background: #5569ff;
        }
    
        .secondary {
            background: #f2f5f9;
        }
    }

    &.greyGoose {
        .primary {
            background: #2442AF;
        }
    
        .secondary {
            background: #F8F8F8;
        }
    }
    
    &.purpleFlow {
        .primary {
            background: #9b52e1;
        }
    
        .secondary {
            background: #00b795;
        }
    }
    
    &.nebulaFighter {
        .primary {
            background: #8C7CF0;
        }
    
        .secondary {
            background: #FFFFFF;
        }
    }

    &.greenFields {
        .primary {
            background: #44a574;
        }
    
        .secondary {
            background: #141c23;
        }
    }

    &.darkSpaces {
        .primary {
            background: #CB3C1D;
        }
    
        .secondary {
            background: #1C1C1C;
        }
    }

  `
);

const CheckSelectedLeft = styled(Box)(
  ({ theme }) => `
    background: ${theme.palette.success.main};
    border-radius: 50px;
    height: 26px;
    width: 26px;
    color: ${theme.palette.success.contrastText};
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left:30%;
    top: 50%;
    margin: -13px 0 0 -13px;
    z-index: 5;

    .MuiSvgIcon-root {
        height: 16px;
        width: 16px;
    }

  `
);
const CheckSelectedRight = styled(Box)(
  ({ theme }) => `
    background: ${theme.palette.success.main};
    border-radius: 50px;
    height: 26px;
    width: 26px;
    color: ${theme.palette.success.contrastText};
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 10%;
    top: 50%;
    margin: -13px 0 0 -13px;
    z-index: 5;

    .MuiSvgIcon-root {
        height: 16px;
        width: 16px;
    }

  `
);

function HeaderUserbox() {
  const { t } = useTranslation();
  const theme = useTheme();
  const setThemeName = useContext(ThemeContext);

  const curThemeName = localStorage.getItem("appTheme") || "GreyGooseTheme";

  const [themeChoose, setTheme] = useState(curThemeName);

  const changeTheme = (theme) => {
    setTheme(theme);
    setThemeName(theme);
  };

  const navigate = useNavigate();

  const { user, logout } = useAuth();

  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNavigate = (path) => {
    handleClose();
    navigate(path);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      navigate("/");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <UserBoxButton color="primary" ref={ref} onClick={handleOpen}>
        <UserAvatar alt={user.username} src={user.avatar} />
      </UserBoxButton>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuUserBox
          sx={{
            minWidth: 210,
          }}
          display="flex"
        >
          <Avatar variant="rounded" alt={user.username} src={user.avatar} />
          <UserBoxText>
            <UserBoxLabel variant="body1">
              {user.username}{" "}
              {user.subscription?.map((item, id) => {
                if (item.subs === "Free") {
                  return (
                    <Tooltip
                      key={id}
                      title={
                        "FREE - Adquira uma subscrição para liberar os recursos da plataforma"
                      }
                    >
                      <Chip
                        label={item.emoji}
                        variant="outlined"
                        color="error"
                        onClick={() => {
                          navigate("/subscription");
                        }}
                      />
                    </Tooltip>
                  );
                } else {
                  return (
                    <Tooltip key={id} title={item.subs}>
                      <Chip
                        label={item.emoji}
                        variant="outlined"
                        color="info"
                      />
                    </Tooltip>
                  );
                }
              })}
            </UserBoxLabel>

            <UserBoxDescription variant="body2">
              {user.level_title}
            </UserBoxDescription>
          
          </UserBoxText>
        </MenuUserBox>
        <Divider
          sx={{
            mb: 0,
          }}
        />
        <MenuListWrapperPrimary disablePadding>
          <MenuItem>
            <ListItemText
              onClick={() => {
                handleNavigate("/dashboards/mylearning");
              }}
              primaryTypographyProps={{
                variant: "h5",
              }}
              component={NavLink}
              primary={t("Meu Aprendizado")}
            />

            <Box display="flex" alignItems="center">
              <ChevronRightTwoToneIcon
                sx={{
                  ml: 1,
                  color: `${theme.colors.alpha.black[30]}`,
                  opacity: 0.8,
                }}
              />
            </Box>
          </MenuItem>
          <Divider />
          <MenuItem>
            <ListItemText
              onClick={() => {
                handleNavigate("/management/users/single/1");
              }}
              primaryTypographyProps={{
                variant: "h5",
              }}
              component={NavLink}
              primary={t("Profile settings")}
            />

            <Box display="flex" alignItems="center">
              <ChevronRightTwoToneIcon
                sx={{
                  ml: 1,
                  color: `${theme.colors.alpha.black[30]}`,
                  opacity: 0.8,
                }}
              />
            </Box>
          </MenuItem>

          <MenuItem>
            <ListItemText
              primaryTypographyProps={{
                variant: "h5",
              }}
              component={NavLink}
              primary={t("Theme")}
            />
            <ButtonWrapper
              className={themeChoose === "NebulaFighterTheme" ? "active" : ""}
              onClick={() => {
                changeTheme(
                  themeChoose === "NebulaFighterTheme"
                    ? "GreyGooseTheme"
                    : "NebulaFighterTheme"
                );
              }}
            >
              {themeChoose === "NebulaFighterTheme" ? (
                <CheckSelectedLeft>
                  <CheckTwoToneIcon />
                </CheckSelectedLeft>
              ) : (
                <CheckSelectedRight>
                  <CheckTwoToneIcon />
                </CheckSelectedRight>
              )}

              <ColorSchemeWrapper className="colorSchemeWrapper nebulaFighter">
                <Box className="primary" />
                <Box className="secondary" />
              </ColorSchemeWrapper>
            </ButtonWrapper>
          </MenuItem>
        </MenuListWrapperPrimary>

        <Divider />
        <Box m={1}>
          <Button color="primary" fullWidth onClick={handleLogout}>
            <LockOpenTwoToneIcon
              sx={{
                mr: 1,
              }}
            />
            {t("Sign out")}
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
