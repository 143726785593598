/**
 *  2022 Sergi S. - https://github.com/sergiss
 */

import React, { useCallback, useContext, useEffect, useState } from "react";
import { Number } from "./Number";
import "./clock.css";
import { Word } from "./Word";
import { Box, Grid, IconButton } from "@mui/material";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import { TagTwoTone } from "@mui/icons-material";
import { Helmet } from "react-helmet-async";
import { WebsocketContext } from "../../contexts/WebSocketContext";
import { is } from "date-fns/locale";

//add props with default value

export const Countdown = ({
  tgt = 20000,
  showMoreTime,
  chall_id,
  showTitle = true,
}) => {
  const [show, setShow] = useState(showMoreTime);
  const [millis, setMillis] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [hours, setHours] = useState(0);
  const [isConnected, receive, send] = useContext(WebsocketContext);

  const [time, setTime] = useState(tgt);

  useEffect(() => {
    if (isConnected) {
      // console.log("WebSocket Header Connected");
      if (receive) {
        const response = JSON.parse(receive);
        //console.log("WebSocket Clock Receive", response);
        switch (response.cmd) {
          case "Running":
            //  setLabUrl(response);
            // setChallengeID(response.id);
            if (response?.time_left) {
              setTime(response.time_left*1000);
            }

            break;
          case "Scheduled":
            if (response?.time_left) {
              setTime(response.time_left*1000);
            }

          case "Reverting":
            // setLabUrl(null);
            break;
  
          case "Stopping":
            // setLabUrl(null);
            break;
          case "Stopped":
            // setLabUrl(null);
            break;
          case "Terminating":
            // setLabUrl(null);
            document.title = "ExtremeHacking";
            break;
        }
      }
    }
  }, [isConnected, receive]);


  useEffect(() => {
    const interval = setInterval(() => {
      setTime((time) => {
        let hour = Math.floor(time / 1000 / 60);
        let second = Math.floor((time / 1000) % 60);
        if (showTitle === true)
          if (hour >= 0 && second >= 0)
          {
            document.title =
            String(hour).padStart(2, "0") +
            ":" +
            String(second).padStart(2, "0");

          }
         
        if (time <= 0) {
          clearInterval(interval);
          setMillis(0);
          setSeconds(0);
          setHours(0);
          return 0;
        } else {
          setMillis(time % 99);
          setSeconds(second);
          setHours(hour);
          return time - 60;
        }
      });
    }, 60);

    return (e) => clearInterval(interval);
  }, []);

  const addTime = () => {
    setTime(tgt);
    console.log("Time Reset ",isConnected, chall_id, tgt)
    if (isConnected) {
      send(JSON.stringify({ cmd: "timeReset", id: chall_id }));
      console.log("CMD Sended",isConnected, chall_id)
    }
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="baseline"
        spacing={2}
      >
        <Grid item>
          <Box
            component="span"
            sx={{
              bgcolor: "primary.dark",
              borderRadius: "5px",
              minwidth: "80px",
              width: "80px",
              height: "25px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
            }}
          >
            <Number value={hours} />
            <Word value={":"} />
            <Number value={seconds} />
          </Box>
        </Grid>
        {show && (
          <Grid item>
            <IconButton
              color="primary"
              aria-label="More Time"
              component="label"
              onClick={addTime}
            >
              <MoreTimeIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </>
  );
};
