/**
 *  2022 Sergi S. - https://github.com/sergiss
 */

import { Box } from '@mui/material'
import React from 'react'

export const Word = ({ value, hidden = false }) => {
  const getStyle = ()=> {
    return {
      visibility:  hidden ? 'hidden' : 'visible'
    }
  }
  return (
    <Box className='digital'
    sx={{//Fixed size
      width: '0.8rem',
      height: '0.5rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '0.5rem',

      }}>
      {value}
    </Box>
  )
}