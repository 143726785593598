import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { tomorrow } from "react-syntax-highlighter/dist/esm/styles/prism";

const styles = {
  terminalWrapper: {
    position: "relative",
  },
  titleBar: {
    display: "flex",
    alignItems: "center",
    padding: "8px",
    backgroundColor: "#333",
    color: "#fff",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    borderBottom: "none", // Remove the bottom border
  },
  buttons: {
    display: "flex",
    marginRight: "8px",
  },
  button: {
    width: "12px",
    height: "12px",
    marginRight: "4px",
    backgroundColor: "#ff5f56",
    borderRadius: "50%",
  },
  button: {
    width: "12px",
    height: "12px",
    marginRight: "4px",
    borderRadius: "50%",
  },
  closeButton: {
    backgroundColor: "#ff5f56", // Red
  },
  minimizeButton: {
    backgroundColor: "#ffd246", // Yellow
  },
  maximizeButton: {
    backgroundColor: "#00c853", // Green
  },
  title: {
    fontSize: "14px",
  },
};

const TerminalCode = ({ code }) => {
  const terminalStyle = {
    ...tomorrow,
    'code[class*="language-"]': {
      ...tomorrow['code[class*="language-"]'],
      margin: "0",
      backgroundColor: "#2C001E",
    },
    'pre[class*="language-"]': {
      ...tomorrow['pre[class*="language-"]'],
      backgroundColor: "#2C001E",
      margin: "0",

      overflow: "auto",
    },
  };

  return (
    <div style={styles.terminalWrapper}>
      <div style={styles.titleBar}>
        <div style={styles.buttons}>
          <div style={{ ...styles.button, ...styles.closeButton }}></div>
          <div style={{ ...styles.button, ...styles.minimizeButton }}></div>
          <div style={{ ...styles.button, ...styles.maximizeButton }}></div>
        </div>
        <div style={styles.title}>Terminal</div>
      </div>
      <SyntaxHighlighter language="bash" style={terminalStyle}>
        {code}
      </SyntaxHighlighter>
    </div>
  );
};

export default TerminalCode;
