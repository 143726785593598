import axiosClient from "../apiClient";

export async function getCTFEvents() {
  return axiosClient.get("/api/ctf/events/");
}

export async function getCTFEventDetail(slug) {
  return axiosClient.get(`/api/ctf/events/${slug}`);
}

export async function getTeams(data) {
  return axiosClient.get("/api/ctf/teams/");
}

export async function createTeam(data) {
  return axiosClient.post("/api/ctf/teams/", data);
}

export async function updateTeam(slug, data) {
  return axiosClient.put(`/api/ctf/teams/${slug}/`, data);
}

export async function getTeamDetail(slug) {
  return axiosClient.get(`/api/ctf/teams/${slug}`);
}

export async function kickUserFromTeam(slug, data) {
  return axiosClient.post(`/api/ctf/teams/${slug}/kick/`, data);
}

export async function makeUserCaptainTeam(slug, data) {
  return axiosClient.post(`/api/ctf/teams/${slug}/make_captain/`, data);
}

export async function joinUserTeam(slug, data) {
  return axiosClient.post(`/api/ctf/teams/${slug}/join/`, data);
}

export async function reject_joinUserTeam(slug, data) {
  return axiosClient.post(`/api/ctf/teams/${slug}/reject_join/`, data);
}

export async function askToJoinTeam(slug, data) {
  return axiosClient.post(`/api/ctf/teams/${slug}/ask_to_join/`, data);
}

export async function destroyTeam(slug) {
  return axiosClient.delete(`/api/ctf/teams/${slug}`);
}

export async function add_team(slug, data) {
  return axiosClient.post(`/api/ctf/events/${slug}/add_team/`, data);
}

export async function add_user(slug, data) {
  return axiosClient.post(`/api/ctf/events/${slug}/add_user/`, data);
}

export async function getMyTeams(slug) {
  return axiosClient.get(`/api/ctf/teams/my_teams`);
}

export async function getEventChallengesCategories(slug) {
  return axiosClient.get(`/api/ctf/events/${slug}/categories/`);
}

export async function getEventChallengesByCategory(slug, data) {
  return axiosClient.post(`/api/ctf/events/${slug}/challenges_by_categ/`, data);
}

export async function getCTFScoreBoard(slug) {
  return axiosClient.get(`/api/ctf/events/${slug}/scoreboard/`);
}

export async function getCTFMyScore(slug) {
  return axiosClient.get(`/api/ctf/events/${slug}/myscore/`);
}