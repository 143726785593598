import { Avatar } from '@mui/material';
import { useState } from 'react';

const  UserAvatar= (props) => {
  const { name, imageUrl } = props;

  const [error, setError] = useState(false);

  const handleImageError = () => {
    setError(true);
  };

  return (
    <Avatar
      alt={name}
      src={imageUrl || undefined}
      onError={handleImageError}
     
      sx={{ bgcolor: error ? '#CCCCCC' : undefined, width: 56, height: 56,margin: 'auto' }}
    >
      {name && name.charAt(0).toUpperCase()}
    </Avatar>
  );
}

export default UserAvatar;