import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import ScrollTop from "./hooks/useScrollTop";
import "nprogress/nprogress.css";
import { Provider } from "react-redux";
import store from "./store";
import App from "./App";
import { SidebarProvider } from "./contexts/SidebarContext";
import { HeaderProvider } from "./contexts/HeaderContext";
import * as serviceWorker from "./serviceWorker";
import { AuthProvider } from "./contexts/DjangoAuthContext";
import { CourseProvider } from "./contexts/CourseContext";
import { CTFProvider } from "./contexts/CTFContext";

ReactDOM.render(
  <HelmetProvider>
    <Provider store={store}>
      <SidebarProvider>
        <HeaderProvider>
          <BrowserRouter>
            <ScrollTop />
            <AuthProvider>
              <CourseProvider>
                <CTFProvider>
                  <App />
                </CTFProvider>
              </CourseProvider>
            </AuthProvider>
          </BrowserRouter>
        </HeaderProvider>
      </SidebarProvider>
    </Provider>
  </HelmetProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
