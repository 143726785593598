
/**
 *  2022 Sergi S. - https://github.com/sergiss
 */

import { Box } from '@mui/material';
import React from 'react'

export const Number = ({value = 0}) => {
  const result = String(value).padStart(2, "0");
  return (
      <Box className='digital'
      sx={{//Fixed size
        width: '1rem',
        height: '0.5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '0.5rem',

        }}>
          {result}
      </Box>
  )
}