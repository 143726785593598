import { useState, createContext } from 'react';

export const HeaderContext = createContext({});

export const HeaderProvider = (props) => {
  const { children,labUrl } = props;
  const [showLabComponent, setShowLabComponent] = useState(false);

  const showHeader = () => {
    setShowLabComponent(true);
  };

  const hideHeader = () => {
    setShowLabComponent(false);
  };

  return (
    <HeaderContext.Provider
      value={{ labUrl, showHeader, hideHeader }}
    >
      {children}
    </HeaderContext.Provider>
  );
};
