import { Suspense, lazy } from "react";

import SuspenseLoader from "../components/SuspenseLoader";

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Events
const CTFEvent = Loader(lazy(() => import("../content/dashboards/CTFEvent")));
const CTFTeam = Loader(lazy(() => import("../content/dashboards/Team")));

const DetailCTFEvent = Loader(
  lazy(() => import("../content/dashboards/CTFEvent/single"))
);

const DetailTeam = Loader(
  lazy(() => import("../content/dashboards/Team/single"))
);

const ChallengeEvent = Loader(
  lazy(() => import("../content/dashboards/CTFEvent/single/event"))
);

const ChallengeDetail = Loader(
  lazy(() =>
    import("../content/dashboards/CTFEvent/single/event/ChallengeDetail")
  )
);

const ctf_event_routes = [
  {
    path: "event",
    children: [
      {
        path: "",
        element: <CTFEvent />,
      },
      {
        path: "detail",
        children: [
          {
            path: ":eventID",
            element: <DetailCTFEvent />,
          },
        ],
      },
      {
        path: "ctf/:eventID/:categoryID",
        element: <ChallengeEvent />,
      },
      {
        path: "ctf/:eventID/:categoryID/:challengeID",
        element: <ChallengeEvent />,
      },
    ],
  },
  {
    path: "team",
    children: [
      {
        path: "",
        element: <CTFTeam />,
      },
      {
        path: "detail",
        children: [
          {
            path: ":teamID",
            element: <DetailTeam />,
          },
        ],
      },
    ],
  },
];

export default ctf_event_routes;
