import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';

import ptBRJSON from './translations/ptBR';
import enJSON from './translations/en';
import esJSON from './translations/es';


const resources = {
 
  ptBR: { translation: ptBRJSON },
  en: { translation: enJSON },
  es: { translation: esJSON },
  
};

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    keySeparator: false,
    lng: 'ptBR',
    fallbackLng: 'ptBR',
    react: {
      useSuspense: true
    },
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
