import { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";

import SuspenseLoader from "../components/SuspenseLoader";

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Subscription = Loader(lazy(() => import("../content/subscription")));
const CheckoutCancel = Loader(
  lazy(() => import("../content/subscription/CheckoutCancel"))
);
const CheckoutSuccess = Loader(
  lazy(() => import("../content/subscription/CheckoutSuccess"))
);

const subscriptionRoutes = [
  {
    path: "",
    element: <Subscription />,
  },
  {
    path: "Subscription",
    element: <Subscription />,
  },
  {
    path: "success/:session_id",
    element: <CheckoutSuccess />,
  },
  {
    path: "cancel",
    element: <CheckoutCancel />,
  },
];

export default subscriptionRoutes;
