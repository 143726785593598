import MarkdownRenderer from "../../../../../../components/MarkdownRenderer";

const Question = (props) => {
  return (
    <div>
      <MarkdownRenderer
        markdown={props.is_solved ? `${props.title} ✅` : props.title}
      />
    </div>
  );
};

export default Question;
