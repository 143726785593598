import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState, createContext, useEffect } from 'react';

export const SidebarCourseContext = createContext({});

export const SidebarCourseProvider = ({ children }) => {
  const theme = useTheme();
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const showSidebar = useMediaQuery(theme.breakpoints.up('lg'));

  useEffect(()=>{
    setSidebarToggle(showSidebar)
  },[showSidebar])


  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const openSidebar = () => {
    if (showSidebar){
      setSidebarToggle(true);
    }
   
  };

  const closeSidebar = () => {
    setSidebarToggle(false);
  };

  return (
    <SidebarCourseContext.Provider
      value={{sidebarToggle, closeSidebar,toggleSidebar,openSidebar}}
    >
      {children}
    </SidebarCourseContext.Provider>
  );
};
