import { useState } from "react";
import { Box, IconButton, Snackbar, Typography } from "@mui/material";
import CopyAllTwoToneIcon from "@mui/icons-material/CopyAllTwoTone";


import { alpha } from "@mui/material/styles";

const CopyToClipboardButton = ({ textToCopy }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const handleClick = () => {
    try {
      navigator.clipboard.writeText(textToCopy).then(() => {
        setMessage("Copied to clipboard");
        setOpen(true);
        console.log("Copied to clipboard");
      }).catch((err) => {
        setMessage("Failed to copy: " + err);
        setOpen(true);
        console.error("Failed to copy: " + err);
      });
    } catch (err) {
      setMessage("Clipboard API not supported: " + err);
      setOpen(true);
      console.error("Clipboard API not supported: " + err);
    }
  };

  return (
    <>
      <Box
        component={"span"}
        sx={{
          padding: "0.5rem",
          
          "&:hover" :{
            backgroundColor: alpha("#ffeb3b", 0.06),
            color: "#ffeb3b",

           
          }
        }}
        onClick={handleClick}
      >
        {textToCopy}
        <IconButton onClick={handleClick} color="primary">
          <CopyAllTwoToneIcon />
        </IconButton>
      </Box>
      <Snackbar
        sx={{
            //change z-index to appear in front all elements
            zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        message={message}
        key={message}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  );
};

export default CopyToClipboardButton;
