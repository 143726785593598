import {
  change_password,
  change_password_token,
  getUserProfile,
  login,
  logout,
  passwordReset,
  register,
  request_email_token,
  request_email_token_auth,
  updateUserProfile,
  update_avatar,
  validate_email,
  validate_password_token,
} from "../../api/lib/auth";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { authActions } from "./auth-slice";

export const doLogin = (params) => {
  return async (dispatch) => {
    return login(params.email, params.password)
      .then((res) => {
        // console.log("LOGIN", res);
        dispatch(authActions.loginSuccess(res.data.data));
        return res;
      })
      .catch((err) => {
        // console.log("ERROR LOGIN", err);
        dispatch(authActions.loginFail(err.response.data));
        return err;
      });
  };
};

export const doLogout = (email, password) => {
  return async (dispatch) => {
    try {
      await logout();
      dispatch(authActions.logout());
      window.location = "/";
    } catch (error) {
      console.error("Error", error);
      dispatch(authActions.loginFail());
    }
  };
};

export const doRegister = (username, email, password) => {
  return async (dispatch) => {
    return register(username, email, password)
      .then((res) => {
        // console.log("Register Success", res);
        dispatch(authActions.registerSuccess(res.data));
        // console.log("Register Success", res);
        return res;
      })
      .catch((err) => {
        // console.log("ERROR Register", err);
        dispatch(authActions.registerFail(err.response.data));
        throw err;
      });
  };
};

export const doUpdateAvatar = (data) => {
  return async (dispatch) => {
    try {
      await update_avatar(data);
    } catch (error) {
      // dispatch(
      //   notificationActions.showNotification({
      //      status: 'error',
      //      title: 'Error to Update the Avatar!',
      //      message: 'Please review and try again later!',
      //    }))
    }
  };
};

export const doUpdateProfile = (
  uuid,
  username,
  bio,
  twitter_username,
  github_username,
  linkedin_username,
  personal_website
) => {
  return async (dispatch) => {
    try {
      await updateUserProfile(
        uuid,
        username,
        bio,
        twitter_username,
        github_username,
        linkedin_username,
        personal_website
      );
      dispatch(authActions.profileUpdate());

      // dispatch(
      //   notificationActions.showNotification({
      //      status: 'info',
      //      title: 'Profile Updated!',
      //      message: 'Very nice!',
      //    }))

      return Promise.resolve();
    } catch (error) {
      // console.error("Error", error);
    }
  };
};

export const doChangePassword = (oldpassword, newpassword1, newpassword2) => {
  return async (dispatch) => {
    try {
      await change_password(oldpassword, newpassword1, newpassword2);
      // dispatch(
      //   notificationActions.showNotification({
      //      status: 'info',
      //      title: 'Password Changed!',
      //      message: 'Password updated successfully!',
      //    }))

      return Promise.resolve();
    } catch (error) {
      // console.error("Error Action", error);

      // dispatch(
      //   notificationActions.showNotification({
      //      status: 'error',
      //      title: 'Error to Change Password!',
      //      message: 'Something wrong happening. Please review and try again later!',
      //    }))
    }
  };
};

export const doChangePasswordToken = (token, newpassword1, newpassword2) => {
  return async (dispatch) => {
    try {
      await change_password_token(token, newpassword1, newpassword2);
      // dispatch(
      //   notificationActions.showNotification({
      //      status: 'info',
      //      title: 'Password Changed!',
      //      message: 'Password updated successfully!',
      //    }))
    } catch (error) {
      // console.error("Error", error);
    }
  };
};

export const doGetUserProfile = createAsyncThunk(
  "authentication/getProfile",
  async (params) => {
    const authData = await getUserProfile();
    return authData.data;
  }
);

export const doVerifyEmail = (token) => {
  return async (dispatch) => {
    try {
      const authData = await validate_email(token);
      // console.log("doVerifyEmail", authData);

      // dispatch(
      // notificationActions.showNotification({
      //    status: 'info',
      //    title: 'Email Validated!',
      //    message: 'Welcome on Board. Please realize Login!',
      //  }))
    } catch (error) {
      dispatch(
        authActions.verifyEmailError({
          error: error.response.data,
        })
      );
    }
  };
};

export const doVerifyPasswordToken = (token) => {
  return async (dispatch) => {
    try {
      const authData = await validate_password_token(token);
      // console.log("doVerifyPasswordToken Token to Change Pass", authData);
      dispatch(
        authActions.changePassToken({
          token: authData.data.token,
        })
      );
    } catch (error) {
      throw Error({
        message: error.response.data,
        status: error.response.status,
      });
    }
  };
};

export const doRequestNewToken = (token) => {
  return async (dispatch) => {
    try {
      await request_email_token(token);
    } catch (error) {
      // console.error("Error doRequestNewToken", error);

      throw { message: error.response.data, status: error.response.status };
    }
  };
};

export const doRequestEmailTokenAuth = (email, password) => {
  return async (dispatch) => {
    try {
      await request_email_token_auth(email, password);
    } catch (error) {
      // console.error("Error doRequestNewToken", error);

      throw { message: error.response.data, status: error.response.status };
    }
  };
};

export const doRequestPasswordReset = (email) => {
  return async (dispatch) => {
    try {
      await passwordReset(email);
    } catch (error) {
      // console.error("Error doRequestPasswordReset", error);

      throw { message: error.response.data, status: error.response.status };
    }
  };
};
