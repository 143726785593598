import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from '../components/SuspenseLoader';

import Guest from '../components/Guest';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Overview = Loader(lazy(() => import('../content/overview')));

// Status
const Obrigado = Loader(
  lazy(() => import('../content/pages/Obrigado'))
);
const Achievement = Loader(
  lazy(() => import('../components/Sharing'))
);
const Status404 = Loader(
  lazy(() => import('../content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('../content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('../content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('../content/pages/Status/Maintenance'))
);

const LoginCover = Loader(
  lazy(() => import('../content/pages/Auth/Login/Cover'))
);

const baseRoutes = [
  {
    path: '/',
    element: (
      <Guest>
        <LoginCover />
      </Guest>
    )
  },
  {
    path: 'overview',
    element: <Navigate to="/" replace />
  },
  {
    path: '*',
    element: <Status404 />
  },
  {
    path: 'obrigado/:session_id',
    element: <Obrigado/>
  },
  // {
  //   path: 'achiviement/:achiviement_id',
  //   element: <Achievement/>
  // },
  {
    path: 'status',
    children: [
      {
        path: '500',
        element: <Status500 />
      },
      {
        path: 'maintenance',
        element: <StatusMaintenance />
      },
      {
        path: 'coming-soon',
        element: <StatusComingSoon />
      }
    ]
  },
  {
    path: '*',
    element: <Status404 />
  }
];

export default baseRoutes;
