import SmartToyTwoToneIcon from "@mui/icons-material/SmartToyTwoTone";
import SchoolTwoToneIcon from "@mui/icons-material/SchoolTwoTone";
import SportsScoreTwoToneIcon from "@mui/icons-material/SportsScoreTwoTone";
import EmojiEventsTwoToneIcon from "@mui/icons-material/EmojiEventsTwoTone";

import StarsTwoToneIcon from "@mui/icons-material/StarsTwoTone";
import IconDiscord from "../../../../components/IconDiscord";

const menuItems = [
  {
    heading: "Menu",
    items: [
      // {
      //   name: "CTF 3K",
      //   icon: EmojiEventsTwoToneIcon,
      //   effect: "grow",
      //   link: "/ctfevents/event/detail/32249222-ctf-xtr-3k",
      // },
      {
        name: "Dashboards",
        icon: SmartToyTwoToneIcon,
        link: "/dashboards",
      },
      {
        name: "My Learning",
        link: "dashboards/mylearning",
        icon: StarsTwoToneIcon,
      },
      {
        name: "Cursos",
        icon: SchoolTwoToneIcon,
        link: "/dashboards/course",
      },
      {
        name: "Desafios",
        icon: SportsScoreTwoToneIcon,
        link: "/dashboards/challenge",
      },
      {
        name: "Eventos CTF",
        icon: EmojiEventsTwoToneIcon,
        link: "",
        items: [
          {
            name: "CTF",
            link: "/ctfevents/event",
          },
          {
            name: "Team",
            link: "/ctfevents/team",
          },
        ],
      },
   
    ],
  },
];

export default menuItems;
