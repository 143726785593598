import { Container } from "@mui/system";
import { Paper } from "@mui/material";
import Question from "./Question";
import SimpleAnswer from "./SimpleAnswer";
import { styled } from "@mui/material/styles";
import True_or_False from "./True_or_False";
import ChallengeComponent from "../../../../../../components/Challenge";
import MarkdownRenderer from "../../../../../../components/MarkdownRenderer";
import ReactMarkdown from "react-markdown";

const Viewarea = styled(ReactMarkdown)`
  padding: 12;
  font-size-adjust: 0.5;
  font-size: 1.5em;
  font-weight: 300;
  line-height: 140%;
  text-align: justify;
  text-justify: inter-word;

  p {
    line-height: calc(1ex / 0.32);
  }

  h1 {
    font-size: 2.5em;
    line-height: calc(1ex / 0.42);
    margin: calc(1ex / 0.42) 0;
  }

  h2 {
    font-size: 2em;
    line-height: calc(1ex / 0.42);
    margin: calc(1ex / 0.42) 0;
  }

  h3 {
    font-size: 1.75em;
    line-height: calc(1ex / 0.38);
    margin: calc(1ex / 0.38) 0;
  }

  h4 {
    font-size: 1.5em;
    line-height: calc(1ex / 0.37);
    margin: calc(1ex / 0.37) 0;
  }

  p {
    font-size: 1em;
    line-height: calc(1ex / 0.32);
    margin: calc(1ex / 0.32) 0;
  }

  img {
    max-width: 100%;
    height: auto;

  }
`;

const LectureQuiz = (props) => {
  const questions_ordered = props.lecture.questions;

  return (
    <Container sx={{ mt: 5 }}>
      {props.lecture.content && (
        <Container>
        <MarkdownRenderer markdown={props.lecture.content} />
      </Container>
        
      )}
      {props.lecture.challenge && (
        <>
          <ChallengeComponent
            key={Math.random()}
            challenge={props.lecture.challenge}
            handleAccepted={undefined}
            isCourse={true}
          />
          <Paper
            sx={{
              width: "100%",
              p: 2,
              mb: 2,
            }}
          >
            <MarkdownRenderer markdown={props.lecture.challenge.instruction} />
          </Paper>
        </>
      )}
      <div>
        {questions_ordered.map((question) => {
          return (
            <Paper
              key={question.id}
              sx={{
                width: "100%",
                p: 2,
                mb: 2,
              }}
            >
              <Question {...question} quiz_id={props.lecture.id} />

              {question.type === "S" && (
                <SimpleAnswer {...question} quiz_id={props.lecture.id} />
              )}
              {question.type === "C" && (
                <SimpleAnswer {...question} quiz_id={props.lecture.id} />
              )}
              {question.type === "M" && (
                <True_or_False {...question} quiz_id={props.lecture.id} />
              )}
              {question.type === "T" && (
                <True_or_False {...question} quiz_id={props.lecture.id} />
              )}
            </Paper>
          );
        })}
      </div>
    </Container>
  );
};

export default LectureQuiz;
