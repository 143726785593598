import { PropaneSharp } from "@mui/icons-material";
import { Button, Link, Typography } from "@mui/material";
import { Container } from "@mui/system";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const LectureFile = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Function will execute on click of button
  const onButtonClick = (event) => {
    event.preventDefault();

    const filename = props.lecture.file.split("\\").pop().split("/").pop();

    fetch(props.lecture.file)
      .then((response) => {
        response.blob().then((blob) => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = filename;
          alink.click();
        });
      })
      .then(() => {
        const { courseId, moduleId } = props;
        const { id } = props.lecture;
        let contentId = id;
      });
  };

  return (
    <Container>
      <Typography variant="h3" component="h4" align="center">
        {t("Your download is ready!")}
      </Typography>
      <br></br>
      <Typography variant="h4" component="h5" align="center">
        {props.lecture.title}
      </Typography>

      <Typography align="center">
        <Link href="#" color="inherit" onClick={onButtonClick}>
          <CloudDownloadIcon fontSize="large" />
        </Link>
      </Typography>
    </Container>
  );
};

export default LectureFile;
