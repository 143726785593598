import { useContext, useEffect, useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  useTheme,
  CardActionArea,
  Typography,
  MenuList,
  Grid,
  Divider,
  Card,
  MenuItem,
  ListItemText,
  alpha,
  Link,
  Popover,
  Stack,
  styled,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import KeyboardArrowDownTwoToneIcon from "@mui/icons-material/KeyboardArrowDownTwoTone";
import ChevronRightTwoToneIcon from "@mui/icons-material/ChevronRightTwoTone";
import Text from "../../../../components/Text";
import AccountTreeTwoToneIcon from "@mui/icons-material/AccountTreeTwoTone";
import EmojiEventsTwoToneIcon from "@mui/icons-material/EmojiEventsTwoTone";
import { SidebarContext } from "../../../../contexts/SidebarContext";
import { WebsocketContext } from "../../../../contexts/WebSocketContext";
import LabComponent from "../../../../components/LabComponent";

import SchoolTwoToneIcon from "@mui/icons-material/SchoolTwoTone";
import SportsScoreTwoToneIcon from "@mui/icons-material/SportsScoreTwoTone";

const LabelWrapper = styled(Box)(
  ({ theme }) => `
  font-size: ${theme.typography.pxToRem(10)};
  font-weight: bold;
  text-transform: uppercase;
  border-radius: ${theme.general.borderRadiusSm};
  padding: ${theme.spacing(0.5, 1, 0.4)};
`
);

const CardActionAreaWrapper = styled(CardActionArea)(
  ({ theme }) => `
      .MuiTouchRipple-root {
        opacity: .2;
      }

      .MuiCardActionArea-focusHighlight {
        background: ${theme.colors.primary.main};
      }

      &:hover {
        .MuiCardActionArea-focusHighlight {
          opacity: .05;
        }
      }
`
);

const MenuListWrapperSecondary = styled(MenuList)(
  ({ theme }) => `
  padding: ${theme.spacing(3)};

  & .MuiMenuItem-root {
      border-radius: 50px;
      padding: ${theme.spacing(1, 1, 1, 2.5)};
      min-width: 200px;
      margin-bottom: 2px;
      position: relative;
      color: ${theme.colors.alpha.black[70]};

      &.Mui-selected,
      &:hover,
      &.MuiButtonBase-root:active {
          background: ${theme.colors.alpha.black[10]};
          color: ${theme.colors.alpha.black[100]};
      }

      &:last-child {
          margin-bottom: 0;
      }
    }
`
);

const MenuListWrapperSuccess = styled(MenuList)(
  ({ theme }) => `
  padding: ${theme.spacing(3)};

  & .MuiMenuItem-root {
      border-radius: 50px;
      padding: ${theme.spacing(1, 1, 1, 2.5)};
      min-width: 200px;
      margin-bottom: 2px;
      position: relative;
      color: ${theme.colors.success.main};

      &.Mui-selected,
      &:hover,
      &.MuiButtonBase-root:active {
          background: ${theme.colors.success.lighter};
          color: ${theme.colors.success.dark};
      }

      &:last-child {
          margin-bottom: 0;
      }
    }
`
);

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        width: ${theme.spacing(4)};
        height: ${theme.spacing(4)};
        border-radius: ${theme.general.borderRadiusLg};
`
);

const MenuListWrapperError = styled(MenuList)(
  ({ theme }) => `
  padding: ${theme.spacing(3)};

  & .MuiMenuItem-root {
      border-radius: 50px;
      padding: ${theme.spacing(1, 1, 1, 2.5)};
      min-width: 200px;
      margin-bottom: 2px;
      position: relative;
      color: ${theme.colors.error.main};

      &.Mui-selected,
      &:hover,
      &.MuiButtonBase-root:active {
          background: ${theme.colors.error.lighter};
          color: ${theme.colors.error.dark};
      }

      &:last-child {
          margin-bottom: 0;
      }
    }
`
);

const DotLegend = styled("span")(
  ({ theme }) => `
    border-radius: 22px;
    width: ${theme.spacing(1.4)};
    height: ${theme.spacing(1.45)};
    display: inline-block;
    border: ${theme.colors.alpha.white[100]} solid 2px;
`
);

function HeaderMenu() {
  const { t } = useTranslation();
  const theme = useTheme();

  const [isConnected, receive, send, isRunning, labId] =
    useContext(WebsocketContext);

  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const [labUrl, setLabUrl] = useState(null);
  const [challId, setChallengeID] = useState(null);
  const [challCreateAT, setChallCreateAT] = useState(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const ref2 = useRef(null);
  const [isOpen2, setOpen2] = useState(false);

  const handleOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  useEffect(() => {
    if (isConnected) {
      // console.log("WebSocket Header Connected");
      if (receive) {
        const response = JSON.parse(receive);
        // console.log("WebSocket Header Receive", response);
        switch (response.cmd) {
          case "Running":
            setLabUrl(response);
            setChallengeID(response.id);
            

            break;
          case "Reverting":
            setLabUrl(null);
            break;
  
          case "Stopping":
            setLabUrl(null);
            break;
          case "Stopped":
            setLabUrl(null);
            break;
          case "Terminating":
            setLabUrl(null);
            document.title = "ExtremeHacking";
            break;
        }
      }
    }
  }, [isConnected, receive]);

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="baseline"
        spacing={2}
      >
        <Grid
          item
          
        >
          <Button
            ref={ref2}
            onClick={handleOpen2}
            endIcon={<KeyboardArrowDownTwoToneIcon />}
            color="secondary"
            size="small"
            sx={{
              backgroundColor: `${theme.colors.primary.lighter}`,
              color: `${theme.colors.secondary.dark}`,

              ".MuiSvgIcon-root": {
                color: `${theme.colors.primary.dark}`,
                transition: `${theme.transitions.create(["color"])}`,
              },

              "&:hover": {
                backgroundColor: `${theme.colors.primary.main}`,
                color: `${theme.palette.getContrastText(
                  theme.colors.secondary.main
                )}`,

                ".MuiSvgIcon-root": {
                  color: `${theme.palette.getContrastText(
                    theme.colors.primary.main
                  )}`,
                },
              },
            }}
          >
            {t("Menu")}
          </Button>
        </Grid>
        <Grid item>
          {labUrl && (
            <LabComponent
              laburl={labUrl}
              is_visible={labUrl ? true : false}
              chall_id={challId}
              createAT={null}
              countDownShow={false}
            />
          )}
        </Grid>
      </Grid>

      <Popover
        disableScrollLock
        anchorEl={ref2.current}
        onClose={handleClose2}
        open={isOpen2}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          ".MuiPopover-paper": {
            background: theme.colors.gradients.blue3,
          },
        }}
      >
        <Box
          sx={{
            maxWidth: 400,
          }}
          p={3}
        >
          <Typography
            variant="h4"
            gutterBottom
            textAlign="center"
            sx={{
              color: theme.colors.alpha.trueWhite[100],
              fontSize: theme.typography.pxToRem(18),
            }}
          >
            {t("Dashboards")}
          </Typography>
          <Typography
            variant="subtitle2"
            textAlign="center"
            sx={{
              color: theme.colors.alpha.trueWhite[70],
            }}
          >
            {t("Dashboard Menu Header")}
          </Typography>
          <Grid container mt={1} spacing={2}>
            <Grid item xs={12} sm={6}>
              <Card>
                <CardActionAreaWrapper
                  sx={{
                    p: 2,
                  }}
                  component={RouterLink}
                  to="/dashboards/course"
                  onClick={handleClose2}
                >
                  <Text color="primary">
                    <SchoolTwoToneIcon
                      sx={{
                        mb: 1,
                      }}
                    />
                  </Text>
                  <Typography variant="h4">{t("Courses")}</Typography>
                </CardActionAreaWrapper>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card>
                <CardActionAreaWrapper
                  sx={{
                    p: 2,
                  }}
                  component={RouterLink}
                  to="/dashboards/challenge"
                  onClick={handleClose2}
                >
                  <Text color="warning">
                    <SportsScoreTwoToneIcon
                      sx={{
                        mb: 1,
                      }}
                    />
                  </Text>
                  <Typography variant="h4">{t("Challenges")}</Typography>
                </CardActionAreaWrapper>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card>
                <CardActionAreaWrapper
                  sx={{
                    p: 2,
                  }}
                  component={RouterLink}
                  to="/dashboards/myLearning"
                  onClick={handleClose2}
                >
                  <Text color="success">
                    <EmojiEventsTwoToneIcon
                      sx={{
                        mb: 1,
                      }}
                    />
                  </Text>
                  <Typography variant="h4">{t("My Learning")}</Typography>
                </CardActionAreaWrapper>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card>
                <CardActionAreaWrapper
                  sx={{
                    p: 2,
                  }}
                  component={RouterLink}
                  to="/ctfevents/event"
                  onClick={handleClose2}
                >
                  <Text color="success">
                    <EmojiEventsTwoToneIcon
                      sx={{
                        mb: 1,
                      }}
                    />
                  </Text>
                  <Typography
                    variant="h4"
                    sx={{
                      marginBottom: "18px",
                    }}
                  >
                    {t("CTF Events")}
                  </Typography>
                </CardActionAreaWrapper>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderMenu;
