import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Card,
  Link,
 
  Typography,
  Container,
 
  styled
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import useAuth from '../../../../../hooks/useAuth';
import LocalLogin from '../LoginLocal';
import { useTranslation } from 'react-i18next';

import { useEffect, useState } from 'react';



const Content = styled(Box)(
  () => `
    display: flex;
    flex: 1;
    width: 100%;
`
);

const MainContent = styled(Box)(
  ({ theme }) => `
  
  width: 100%;
  display: flex;
  align-items: center;
`
);


function LoginCover() {
  const { method } = useAuth();
  const { t } = useTranslation();

  const [utmSource, setUtmSource] = useState(null);
  const [utmMedium, setUtmMedium] = useState(null);
  const [utmCampaign, setUtmCampaign] = useState(null);

  useEffect(() => {


    const params = new URLSearchParams(window.location.search);
    const utmSource = params.get('utm_source');
    const utmMedium = params.get('utm_medium');
    const utmCampaign = params.get('utm_campaign');

    if (utmSource) {
      localStorage.setItem('utm_source', utmSource);
      setUtmSource(utmSource);
    }

    if (utmMedium) {
      localStorage.setItem('utm_medium', utmMedium);
      setUtmMedium(utmMedium);
    }

    if (utmCampaign) {
      localStorage.setItem('utm_campaign', utmCampaign);
      setUtmCampaign(utmCampaign);
    }
  }, []);

    


  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Content>
    
        <MainContent>
          <Container
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}
            maxWidth="sm"
          >
            <Card
              sx={{
                p: 4,
                my: 4
              }}
            >
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  sx={{
                    mb: 1
                  }}
                >
                  {t('Sign in')}
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  fontWeight="normal"
                  sx={{
                    mb: 3
                  }}
                >
                  {t('Fill in the fields below to sign into your account.')}
                </Typography>
              </Box>
              {method === 'Local' && <LocalLogin />}
              <Box my={4}>
                <Typography
                  component="span"
                  variant="subtitle2"
                  color="text.primary"
                  fontWeight="bold"
                >
                  {t('Don’t have an account, yet?')}
                </Typography>{' '}
                <Box display={{ xs: 'block', md: 'inline-block' }}>
                  <Link component={RouterLink} to={`/account/register/?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}`}>
                      <b>{t('Sign up here')}</b>
                  </Link>
                  
                </Box>
              </Box>
              
            </Card>
          </Container>
        </MainContent>
     
      </Content>
    </>
  );
}

export default LoginCover;
