import { createContext, useEffect, useReducer, useState } from "react";
import PropTypes from "prop-types";
import {
  getUserProfile as call_getUserProfile,
  login as call_login,
  register as call_register,
} from "../api/lib/auth";

const initialAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  error: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    // console.log("INITIALIZE", action.payload);
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      error: null,
    };
  },
  LOGINSUCCESS: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      error: null,
    };
  },
  UPDATEUSER: (state, action) => {
    const { user } = action.payload;
    // console.log("UPDATEUSER", action.payload);
    return {
      ...state,
      isAuthenticated: user ? true : false,
      user,
      error: null,
    };
  },
  LOGINFAIL(state, action) {
    // console.log("LOGINFAIL", action.payload);
    return {
      ...state,
      isLoggedIn: false,
      error: action.payload.error,
      user: null,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    error: null,
  }),
  REGISTERSUCCESS: (state, action) => {
    return {
      ...state,
      isAuthenticated: true,
      error: null,
    };
  },
  REGISTERFAIL: (state, action) => {
    return {
      ...state,
      error: action.payload.error,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialAuthState,
  method: "Local",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  getUserProfile: () => Promise.resolve(),
  reloadUser: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("session");

        if (accessToken) {
          const response = await call_getUserProfile().then((response) => {
            dispatch({
              type: "INITIALIZE",
              payload: {
                isAuthenticated: response.data ? true : false,
                user: response.data,
              },
            });
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    try {
      const response = await call_login(email, password);
      // console.log("LOGIN RESPONSE", response);
      const { user } = response.data.data;

      dispatch({
        type: "LOGINSUCCESS",
        payload: {
          user,
        },
      });
    } catch (err) {
      // console.log("ERROR LOGIN", err);
      dispatch({
        type: "LOGINFAIL",
        payload: {
          error: err.response.data,
        },
      });
    }
  };

  const logout = async () => {
    // console.log("LOGOUT REMOVE SESSION");
    window.localStorage.removeItem("session");
    dispatch({ type: "LOGOUT" });
  };

  const register = async (email, name, password) => {
    try {
      const response = await call_register(email, name, password);

      dispatch({
        type: "REGISTERSUCCESS",
        payload: response.data,
      });
    } catch (err) {
      // console.log("ERROR REGISTER", err);
      dispatch({
        type: "REGISTERFAIL",
        payload: {
          error: err.response.data,
        },
      });
    }
  };

  const getUserProfile = async () => {
    // console.log("GET USER PROFILE");
    await call_getUserProfile().then((response) => {
      // console.log("GET USER PROFILE RESPONSE", response);

      dispatch({
        type: "UPDATEUSER",
        payload: {
          isAuthenticated: true,
          user: response.data,
        },
      });
    });
  };

  const reloadUser = async () => {
    setReload(true);
  };

  useEffect(() => {
    if (reload) {
      getUserProfile();
      setReload(false);
    }
  }, [reload]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "Local",
        login,
        logout,
        register,
        getUserProfile,
        reloadUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
