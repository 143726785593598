import { createContext, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useRefMounted from "../hooks/useRefMounted";
import {
  getCTFEventDetail,
  getEventChallengesByCategory,
  getEventChallengesCategories,
} from "../api/lib/ctf_event";
import { set } from "nprogress";

const CTFContext = createContext(
  null,
  null,
  null,
  null,
  () => {},
  () => {},
  () => {}
);

export const CTFProvider = ({ children }) => {
  const [eventParams, setEventParams] = useState();
  const [event, setEvent] = useState();
  const [categories, setCategories] = useState();

  const [challengesByCategory, setChallengesByCategory] = useState();

  const [challengeContent, setChallengeContent] = useState();

  const [loadContent, setLoadContent] = useState();

  const [updateURL, setUpdateURL] = useState(false);
  const [newURL, setNewUrl] = useState();

  const isMountedRefGetEvent = useRefMounted();
  const isMountedRefCategories = useRefMounted();
  const isMountedRefGetChallengeByCategory = useRefMounted();

  const navigate = useNavigate();

  useEffect(() => {
    // console.log("setEventValues ", eventParams);
  }, [eventParams]);

  const setEventValues = (eventId, categoryId, challengeId) => {
    setEventParams({ eventId, categoryId, challengeId });
  };

  const setChallengeContentValues = (content) => {
    setChallengeContent(content);
  };

  const getCTFEventDetailAPI = useCallback(async () => {
    if (eventParams) {
      try {
        // console.log("SLuG", eventParams.eventId);
        const response = await getCTFEventDetail(eventParams.eventId);

        if (isMountedRefGetEvent.current) {
          setEvent(response.data);
        }
      } catch (err) {
        // console.log("getCTFEventDetailAPI", err);
        if (err.response.status === 404) {
          navigate("/404");
        } else if (err.response.status === 403) {
          navigate("/ctfevents/event/");
        }
      }
    }
  }, [eventParams, isMountedRefGetEvent]);

  const getEventChallengesCategoriesAPI = useCallback(async () => {
    if (event) {
      try {
        const response = await getEventChallengesCategories(event.slug);
        const data = await response.data;

        setCategories(data);
      } catch (error) {
        // console.log("getEventChallengesCategoriesAPI", error);
      }
    }
  }, [event, isMountedRefCategories]);

  const getChallengesByCategoryAPI = useCallback(async () => {
    if (eventParams) {
      try {
        const response = await getEventChallengesByCategory(
          eventParams.eventId,
          {
            category_id: eventParams.categoryId,
          }
        );

        if (isMountedRefGetChallengeByCategory.current) {
          setChallengesByCategory(response.data);
          //find the challengeID on the challengesByCategory
          if (eventParams.challengeId) {
            const challenge = response.data.find(
              (challenge) => challenge.id === eventParams.challengeId
            );

            setChallengeContent(challenge);
          }
        }
      } catch (err) {
        console.error("GET Event Detail", err);
        if (err.response.status === 404) {
          navigate("/404");
        }
      }
    }
  }, [eventParams, isMountedRefGetChallengeByCategory]);

  const handleLoad = () => {
    setLoadContent(true);
  };

  useEffect(() => {
    getCTFEventDetailAPI();
  }, [getCTFEventDetailAPI]);

  useEffect(() => {
    getEventChallengesCategoriesAPI();
  }, [getEventChallengesCategoriesAPI]);

  useEffect(() => {
    getChallengesByCategoryAPI();
  }, [getChallengesByCategoryAPI]);

  useEffect(() => {
    if (loadContent) {
      getChallengesByCategoryAPI();
      setLoadContent(false);
    }
  }, [getChallengesByCategoryAPI, loadContent]);

  return (
    <CTFContext.Provider
      value={{
        event,
        categories,
        challengesByCategory,
        challengeContent,
        setEventValues,
        handleLoad,
        setChallengeContentValues,
      }}
    >
      {children}
    </CTFContext.Provider>
  );
};

export default CTFContext;
