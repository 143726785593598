import { SvgIcon } from "@mui/material";
import * as React from "react";


export default function ExtremeIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 53 53" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="layer1">
      <path
        style={{ opacity: 0.98, fill: "#ffff00", fillOpacity: 1, stroke: "none", strokeWidth: 0.137534, strokeOpacity: 1 }}
        d="M 22.058717,52.146271 C 12.482992,50.385671 5.2916322,44.492462 2.1225195,35.80892 -1.7762432,25.126048 1.6076903,12.748109 10.192859,6.288947 c 1.939632,-1.459319 6.879863,-4.316216 7.463704,-4.316216 0.107688,0 0.19581,2.326827 0.19581,5.170727 v 5.170731 l -1.175542,0.633077 c -2.671223,1.438528 -5.525046,5.556433 -6.393077,9.224821 -0.5979527,2.527033 -0.4468987,7.496874 0.300262,9.878447 1.71317,5.460692 6.584312,9.682564 12.185803,10.561614 6.419849,1.007444 11.645615,-0.351973 15.668322,-4.076486 3.313649,-3.067829 4.657137,-6.522569 4.657137,-11.975683 0,-1.766816 -0.221188,-3.995205 -0.505627,-5.094449 -1.031832,-3.98734 -3.821042,-7.294193 -7.94368,-9.418002 -1.759967,-0.906662 -2.102061,-0.988547 -2.371745,-0.567728 -0.179517,0.280107 -0.317291,3.983354 -0.320159,8.605534 l -0.0048,8.113801 H 27.359666 22.770047 V 14.430259 0.66141299 l 0.901533,0.0029 c 2.667505,0.0088 8.748827,0.74816501 10.837828,1.31856001 5.755946,1.57163 10.60583,5.233126 14.176379,10.702654 4.697877,7.19644 5.085474,18.499806 0.904831,26.387456 -3.171176,5.983097 -9.215303,10.708323 -15.876353,12.411915 -2.903871,0.742674 -9.253604,1.103032 -11.655335,0.661466 z"
        id="path1605"
        width="53mm"
        height="53mm"
      />
    </g>
  </SvgIcon>
  );
}
