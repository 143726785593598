import { useRef, useState } from "react";

import {
  IconButton,
  Box,
  List,
  ListItem,
  Divider,
  Typography,
  ListItemText,
  alpha,
  Popover,
  Tooltip,
  styled,
  useTheme,
} from "@mui/material";
import Text from "../../../../../components/Text";
import internationalization from "../../../../../i18n/i18n";
import { useTranslation } from "react-i18next";

import { BR } from "country-flag-icons/react/3x2";
import { US } from "country-flag-icons/react/3x2";
import { ES } from "country-flag-icons/react/3x2";

const SectionHeading = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
        padding: ${theme.spacing(2, 2, 0)};
`
);

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
  border-radius: ${theme.general.borderRadiusLg};
`
);

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const getLanguage = i18n.language;
  const theme = useTheme();

  const switchLanguage = ({ lng }) => {
    internationalization.changeLanguage(lng);
  };
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip arrow title={t("Language Switcher")}>
        <IconButtonWrapper
          color="secondary"
          ref={ref}
          onClick={handleOpen}
          sx={{
            mx: 1,
            background: alpha(theme.colors.error.main, 0.1),
            transition: `${theme.transitions.create(["background"])}`,
            color: theme.colors.error.main,

            "&:hover": {
              background: alpha(theme.colors.error.main, 0.2),
            },
          }}
        >
          {getLanguage === "ptBR" && <BR title="Português Brazil" />}
          {getLanguage === "es" && <ES title="Spanish" />}
          {getLanguage === "en" && <US title="English" />}
        </IconButtonWrapper>
      </Tooltip>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            maxWidth: 240,
          }}
        >
          <SectionHeading variant="body2" color="text.primary">
            {t("Language Switcher")}
          </SectionHeading>
          <List
            sx={{
              p: 2,
              svg: {
                width: 26,
                mr: 1,
              },
            }}
            component="nav"
          >
            <ListItem
              className={
                getLanguage === "ptBR" || getLanguage === "ptBR" ? "active" : ""
              }
              button
              onClick={() => {
                switchLanguage({ lng: "ptBR" });
                handleClose();
              }}
            >
              <BR title="Português Brasil" />
              <ListItemText
                sx={{
                  pl: 1,
                }}
                primary="Português Brasil"
              />
            </ListItem>
            <ListItem
              className={
                getLanguage === "en" || getLanguage === "en-US" ? "active" : ""
              }
              button
              onClick={() => {
                switchLanguage({ lng: "en" });
                handleClose();
              }}
            >
              <US title="English" />
              <ListItemText
                sx={{
                  pl: 1,
                }}
                primary="English"
              />
            </ListItem>
            <ListItem
              className={getLanguage === "es" ? "active" : ""}
              button
              onClick={() => {
                switchLanguage({ lng: "es" });
                handleClose();
              }}
            >
              <ES title="Spanish" />
              <ListItemText
                sx={{
                  pl: 1,
                }}
                primary="Spanish"
              />
            </ListItem>
            {/* <ListItem
              className={getLanguage === 'de' ? 'active' : ''}
              button
              onClick={() => {
                switchLanguage({ lng: 'de' });
                handleClose();
              }}
            >
              <DE title="German" />
              <ListItemText
                sx={{
                  pl: 1
                }}
                primary="German"
              />
            </ListItem> */}

            {/* <ListItem
              className={getLanguage === 'de' ? 'active' : ''}
              button
              onClick={() => {
                switchLanguage({ lng: 'de' });
                handleClose();
              }}
            >
              <DE title="German" />
              <ListItemText
                sx={{
                  pl: 1
                }}
                primary="German"
              />
            </ListItem>
            <ListItem
              className={getLanguage === 'es' ? 'active' : ''}
              button
              onClick={() => {
                switchLanguage({ lng: 'es' });
                handleClose();
              }}
            >
              <ES title="Spanish" />
              <ListItemText
                sx={{
                  pl: 1
                }}
                primary="Spanish"
              />
            </ListItem>
            <ListItem
              className={getLanguage === 'fr' ? 'active' : ''}
              button
              onClick={() => {
                switchLanguage({ lng: 'fr' });
                handleClose();
              }}
            >
              <FR title="French" />
              <ListItemText
                sx={{
                  pl: 1
                }}
                primary="French"
              />
            </ListItem>
            <ListItem
              className={getLanguage === 'cn' ? 'active' : ''}
              button
              onClick={() => {
                switchLanguage({ lng: 'cn' });
                handleClose();
              }}
            >
              <CN title="Chinese" />
              <ListItemText
                sx={{
                  pl: 1
                }}
                primary="Chinese"
              />
            </ListItem>
            <ListItem
              className={getLanguage === 'ae' ? 'active' : ''}
              button
              onClick={() => {
                switchLanguage({ lng: 'ae' });
                handleClose();
              }}
            >
              <AE title="Arabic" />
              <ListItemText
                sx={{
                  pl: 1
                }}
                primary="Arabic"
              />
            </ListItem> */}
          </List>
          <Divider />
          <Text color="warning">
            <Box
              p={1.5}
              display="flex"
              alignItems="flex-start"
              sx={{
                maxWidth: 340,
              }}
            >
              {/* <WarningTwoToneIcon fontSize="small" />
              <Typography
                variant="body1"
                sx={{
                  pl: 1,
                  fontSize: theme.typography.pxToRem(12)
                }}
              >
                {t(
                  'We only translated a small part of the template, for demonstration purposes'
                )}
                !
              </Typography> */}
            </Box>
          </Text>
        </Box>
      </Popover>
    </>
  );
}

export default LanguageSwitcher;
