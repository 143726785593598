import * as Yup from "yup";

import { Formik } from "formik";
import { Link as RouterLink } from "react-router-dom";

import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Checkbox,
  Typography,
  Link,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import useAuth from "../../../../hooks/useAuth";
import useRefMounted from "../../../../hooks/useRefMounted";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const LoginLocal = () => {
  const { login, error } = useAuth();

  const isMountedRef = useRefMounted();
  const { t } = useTranslation();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const utmSource = params.get("utm_source");
    const utmMedium = params.get("utm_medium");
    const utmCampaign = params.get("utm_campaign");

    localStorage.setItem("utm_source", utmSource);
    localStorage.setItem("utm_medium", utmMedium);
    localStorage.setItem("utm_campaign", utmCampaign);

    window.dataLayer.push({
      event: "utmEvent",
      utm_source: utmSource,
      utm_medium: utmMedium,
      utm_campaign: utmCampaign,
    });
  }, []);



  return (
    <>
      {error && <FormHelperText error>{t(error)}</FormHelperText>}
      <Formik
        initialValues={{
          email: "",
          password: "",
          terms: true,
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(t("The email provided should be a valid email address"))
            .max(255)
            .required(t("The email field is required")),
          password: Yup.string()
            .max(255)
            .required(t("The password field is required")),
          terms: Yup.boolean().oneOf(
            [true],
            t("You must agree to our terms and conditions")
          ),
        })}
        handleChange={async (values, { setErrors, errors }) => {
          if (errors) {
            setErrors("");
          }
        }}
        onSubmit={async (
          values,
          { setErrors, setStatus, setSubmitting, resetForm }
        ) => {
          try {
            await login(values.email, values.password);
            if (isMountedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            if (isMountedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
          resetForm({ values: "" });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth
              margin="normal"
              autoFocus
              helperText={touched.email && errors.email}
              label={t("Email address")}
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
              autoComplete="off"
            />
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              margin="normal"
              helperText={touched.password && errors.password}
              label={t("Password")}
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.password}
              variant="outlined"
              autoComplete="off"
            />
            <Box
              alignItems="center"
              display={{ xs: "block", md: "flex" }}
              justifyContent="space-between"
            >
              <Box display={{ xs: "block", md: "flex" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.terms}
                      name="terms"
                      color="primary"
                      onChange={handleChange}
                    />
                  }
                  label={
                    <>
                      <Typography variant="body2">
                        {t("I accept the")}{" "}
                        <Link component="a" target="_blank" rel="noopener noreferrer" href="https://st4tic.s3.bhs.io.cloud.ovh.net/media/chall/Termos_de_UsoExtremehacking.pdf">
                          {t("terms and conditions")}
                        </Link>
                        .
                      </Typography>
                    </>
                  }
                />
              </Box>
              <Link component={RouterLink} to="/account/recover-password">
                <b>{t("Lost password?")}</b>
              </Link>
            </Box>

            {Boolean(touched.terms && errors.terms) && (
              <FormHelperText error>{errors.terms}</FormHelperText>
            )}

            <Button
              sx={{
                mt: 3,
              }}
              color="primary"
              startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
              disabled={isSubmitting}
              type="submit"
              fullWidth
              size="large"
              variant="contained"
            >
              {t("Sign in")}
            </Button>
          </form>
        )}
      </Formik>
    </>
  );
};

export default LoginLocal;
