import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import useAuth from '../../hooks/useAuth';

const Subscribe = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user } = useAuth();


    return (
        user.is_subscribed === false ? (
            <Button 
                variant="contained" 
                color="success"
                sx = {{
                    p: 1,
                    minWidth: '200px',
                    '@keyframes shake': {
                    '0%': { transform: 'translateX(0)' },
                    '10%': { transform: 'translateX(-5px)' },
                    '20%': { transform: 'translateX(5px)' },
                    '30%': { transform: 'translateX(-5px)' },
                    '40%': { transform: 'translateX(5px)' },
                    '50%': { transform: 'translateX(-5px)' },
                    '60%': { transform: 'translateX(5px)' },
                    '70%': { transform: 'translateX(-5px)' },
                    '80%': { transform: 'translateX(5px)' },
                    '90%': { transform: 'translateX(-5px)' },
                    '100%': { transform: 'translateX(0)' },
                },
                    animation: 'shake 10s linear infinite',
                    animationIterationCount: 'infinite',
                    animationDelay: '10s',

                }}
                onClick={() => {
                    navigate("/subscription");
                }}
            >
                {t('Subscribe')}
            </Button>
        ): null
    );
};

export default Subscribe;